import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getGoodsReceiptNotes() {
    return async (dispatch) => {
        dispatch({ type: types.GOODDRECEIPTNOTE_FETCH_START });
        await client()
            .get(`${apiUrl}/GoodsReceiptNote`)
            .then((data) => {
                dispatch({
                    type: types.GOODDRECEIPTNOTE_FETCH_SUCCESS,
                    data: data.data || [],
                });
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: types.GOODDRECEIPTNOTE_FETCH_FAILURE,
                    errors: err,
                });
                throw err;
            });
    };
}

export function createGoodsReceiptNote(data, file) {
    // const { access_token } = getUser();
    const formData = new FormData();
    formData.append('inputFile', file);
    formData.append('branch', data.branch);
    formData.append('quantity', data.quantity);
    formData.append('receiptDate', data.receiptDate);
    formData.append('supplierId', data.supplierId);
    formData.append('vehiclesType', data.vehiclesType);

    return async (dispatch, state) => {
        const { data: result } = await client().post(
            `${apiUrl}/GoodsReceiptNote`,
            formData,
            {
                'Content-Type': 'multipart/form-data',
            }
        );
        return result;
    };
}

export function updateGoodsReceiptNote(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/GoodsReceiptNote/id?id=${data.id}`,
            data
        );
        return result;
    };
}
