import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Sidebar from './Sidebar';

export class Layout extends Component {
    static displayName = Layout.name;
    render() {
        return (
            <div className="m-0 antialiased text-base leading-default bg-gray-50 text-slate-500 min-h-screen">
                <Sidebar />
                <div className="ease-soft-in-out xl:ml-68.5 relative h-full rounded-xl transition-all duration-200 ">
                    <NavMenu />
                    <Container className="w-full px-6 py-6 mx-auto">
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
