import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { memo, useState } from 'react';
import { createProduct, updateProduct } from '../../../redux/actions/product';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import ButtonCancel from '../../../components/elements/ButtonCancel';
import { uid } from '../../../helpers/utility';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import SelectManufacturer from '../../../components/SelectManufacturer';
import { connect } from 'react-redux';

const FormAddDetail = ({
    openAddDetail,
    setOpenAddDetail,
    currentData,
    setList,
    list,
    isViewMaster,
    setCurrentData,
    updateProduct,
    createProduct,
    masterId,
}) => {
    const isViewDetail = !_.isEmpty(currentData);
    const [form] = Form.useForm();
    const [registrationDate, setRegistrationDate] = useState(
        _.get(currentData, 'registrationDate')
            ? dayjs(currentData.registrationDate)
            : null
    );

    const handleCancel = () => {
        setOpenAddDetail(false);
        setCurrentData();
    };

    const handleSaleDateChange = (value) => {
        setRegistrationDate(value);
    };

    const updateProductOfReceiptNote = async (product) => {
        await updateProduct({
            ...product,
            registrationDate:
                registrationDate &&
                dayjs(registrationDate).format('YYYY-MM-DD'),
        });
    };

    const createProductOfReceiptNote = async (product) => {
        const data = await createProduct({
            ...product,
            registrationDate:
                registrationDate &&
                dayjs(registrationDate).format('YYYY-MM-DD'),
            goodsReceiptNoteId: masterId,
        });
        return data.id;
    };

    const handleOk = () => {
        let id;
        form.validateFields().then(async (values) => {
            const detailId = uid();
            if (isViewMaster && isViewDetail) {
                await updateProductOfReceiptNote({
                    ...values,
                    id: currentData.id || currentData.detailId,
                });
            }
            if (isViewMaster && !isViewDetail) {
                id = await createProductOfReceiptNote({
                    ...values,
                });
            }
            if (isViewDetail) {
                const newData = _.map(list, (item) => {
                    if (
                        item.detailId === currentData.detailId &&
                        !isViewMaster
                    ) {
                        return {
                            ...values,
                            // ...productInfo,
                            detailId: currentData.detailId,
                            registrationDate:
                                registrationDate &&
                                dayjs(registrationDate).format('YYYY-MM-DD'),
                        };
                    }
                    if (item.id === currentData.id && isViewMaster) {
                        return {
                            ...values,
                            // ...productInfo,
                            detailId: currentData.detailId,
                            id: currentData.id,
                            registrationDate:
                                registrationDate &&
                                dayjs(registrationDate).format('YYYY-MM-DD'),
                        };
                    }
                    return item;
                });
                setList(newData);
            } else {
                list.push({
                    ...values,
                    registrationDate,
                    detailId: id ? id : detailId,
                    // ...productInfo,
                });
                setList(list);
            }
            setOpenAddDetail(false);
        });
    };

    return (
        <Modal
            title={
                isViewDetail || isViewMaster
                    ? 'Thông tin chi tiết  đơn hàng'
                    : 'Thêm mới chi tiết'
            }
            open={openAddDetail}
            width={600}
            centered
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd
                            icon={
                                isViewDetail ? (
                                    <SaveOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                ) : (
                                    <PlusOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                )
                            }
                            onClick={handleOk}
                            text={isViewDetail || isViewMaster ? 'Lưu' : 'Tạo'}
                        />
                    </div>
                </>
            }
        >
            <Form initialValues={currentData} layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="name"
                            label="Tên xe"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập!',
                                },
                            ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="color"
                            label="Màu"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập!',
                                },
                            ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="frameNumber"
                            label="Số khung"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập!',
                                },
                            ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="engineNumber"
                            label="Số máy"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Vui lòng nhập!',
                            //     },
                            // ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="price"
                            label="Giá"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Vui lòng nhập!',
                            //     },
                            // ]}
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="numberContracts"
                            label="Số hóa đơn"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Vui lòng nhập!',
                            //     },
                            // ]}
                        >
                            <Input className="w-full" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Ngày nhận đăng kiểm"
                            initialValue={dayjs()}
                        >
                            <DatePicker
                                onChange={handleSaleDateChange}
                                className="w-full"
                                format={'DD/MM/YYYY'}
                                value={registrationDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <SelectManufacturer />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={6}>
                        <Form.Item
                            valuePropName="checked"
                            name="registry"
                            label=""
                        >
                            <Checkbox>Đăng kiểm</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item
                            valuePropName="checked"
                            name="warrantyNumber"
                            label=""
                        >
                            <Checkbox>Số bảo hành</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item name="note" label="Ghi chú">
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default connect(null, {
    updateProduct,
    createProduct,
})(FormAddDetail);
