// Redux Action

// MANUFACTURER
export const MANUFACTURER_FETCH_START = 'MANUFACTURER_FETCH_START';
export const MANUFACTURER_FETCH_SUCCESS = 'MANUFACTURER_FETCH_SUCCESS';
export const MANUFACTURER_FETCH_FAILURE = 'MANUFACTURER_FETCH_FAILURE';
export const MANUFACTURER_UPDATE_FINISH = 'MANUFACTURER_UPDATE_FINISH';
export const MANUFACTURER_DELETE_FINISH = 'MANUFACTURER_DELETE_FINISH';

// PRODUCT
export const PRODUCT_FETCH_START = 'PRODUCT_FETCH_START';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_FAILURE = 'PRODUCT_FETCH_FAILURE';
export const PRODUCT_UPDATE_FINISH = 'PRODUCT_UPDATE_FINISH';
export const PRODUCT_DELETE_FINISH = 'PRODUCT_DELETE_FINISH';

// EMPLOYEE
export const EMPLOYEE_FETCH_START = 'EMPLOYEE_FETCH_START';
export const EMPLOYEE_FETCH_SUCCESS = 'EMPLOYEE_FETCH_SUCCESS';
export const EMPLOYEE_FETCH_FAILURE = 'EMPLOYEE_FETCH_FAILURE';
export const EMPLOYEE_UPDATE_FINISH = 'EMPLOYEE_UPDATE_FINISH';
export const EMPLOYEE_DELETE_FINISH = 'EMPLOYEE_DELETE_FINISH';

// CUSTOMER
export const CUSTOMER_FETCH_START = 'CUSTOMER_FETCH_START';
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS';
export const CUSTOMER_FETCH_FAILURE = 'CUSTOMER_FETCH_FAILURE';
export const CUSTOMER_UPDATE_FINISH = 'CUSTOMER_UPDATE_FINISH';
export const CUSTOMER_DELETE_FINISH = 'CUSTOMER_DELETE_FINISH';

// ORDER
export const ORDER_FETCH_START = 'ORDER_FETCH_START';
export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_SUCCESS';
export const ORDER_FETCH_FAILURE = 'ORDER_FETCH_FAILURE';
export const ORDER_UPDATE_FINISH = 'ORDER_UPDATE_FINISH';
export const ORDER_DELETE_FINISH = 'ORDER_DELETE_FINISH';

// BANKINFO
export const BANKINFO_FETCH_START = 'BANKINFO_FETCH_START';
export const BANKINFO_FETCH_SUCCESS = 'BANKINFO_FETCH_SUCCESS';
export const BANKINFO_FETCH_FAILURE = 'BANKINFO_FETCH_FAILURE';
export const BANKINFO_UPDATE_FINISH = 'BANKINFO_UPDATE_FINISH';
export const BANKINFO_DELETE_FINISH = 'BANKINFO_DELETE_FINISH';

//Authen
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
//  PARTNER
export const PARTNER_FETCH_START = 'PARTNER_FETCH_START';
export const PARTNER_FETCH_SUCCESS = 'PARTNER_FETCH_SUCCESS';
export const PARTNER_FETCH_FAILURE = 'PARTNER_FETCH_FAILURE';
export const PARTNER_UPDATE_FINISH = 'PARTNER_UPDATE_FINISH';
export const PARTNER_DELETE_FINISH = 'PARTNER_DELETE_FINISH';
//  LicensePlateService
export const LICENSEPS_FETCH_START = 'LICENSEPS_FETCH_START';
export const LICENSEPS_FETCH_SUCCESS = 'LICENSEPS_FETCH_SUCCESS';
export const LICENSEPS_FETCH_FAILURE = 'LICENSEPS_FETCH_FAILURE';
export const LICENSEPS_UPDATE_FINISH = 'LICENSEPS_UPDATE_FINISH';
export const LICENSEPS_DELETE_FINISH = 'LICENSEPS_DELETE_FINISH';

// CUSTOMER
export const GOODDRECEIPTNOTE_FETCH_START = 'GOODDRECEIPTNOTE_FETCH_START';
export const GOODDRECEIPTNOTE_FETCH_SUCCESS = 'GOODDRECEIPTNOTE_FETCH_SUCCESS';
export const GOODDRECEIPTNOTE_FETCH_FAILURE = 'GOODDRECEIPTNOTE_FETCH_FAILURE';
export const GOODDRECEIPTNOTE_UPDATE_FINISH = 'GOODDRECEIPTNOTE_UPDATE_FINISH';
export const GOODDRECEIPTNOTE_DELETE_FINISH = 'GOODDRECEIPTNOTE_DELETE_FINISH';

// SUPPLIER
export const SUPPLIER_FETCH_START = 'SUPPLIER_FETCH_START';
export const SUPPLIER_FETCH_SUCCESS = 'SUPPLIER_FETCH_SUCCESS';
export const SUPPLIER_FETCH_FAILURE = 'SUPPLIER_FETCH_FAILURE';
export const SUPPLIER_UPDATE_FINISH = 'SUPPLIER_UPDATE_FINISH';
export const SUPPLIER_DELETE_FINISH = 'SUPPLIER_DELETE_FINISH';
