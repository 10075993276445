import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import client from './client';
import apiUrl from '../settings';
export const useData = (uri, params, options) => {
    const [state, setState] = useState({ loading: false });

    const method = _.get(options, 'method', 'GET').toLowerCase();
    const notFetch = _.get(options, 'notFetch');

    const fetch = useCallback(
        async (loading = true) => {
            if (!uri) return;
            if (loading) {
                setState((state) => ({
                    ...state,
                    loading: true,
                }));
            }
            const data = await client(!!_.get(options, 'ignoreError'))[method](
                apiUrl + uri,
                {
                    params,
                }
            );

            setState({
                loading: false,
                loaded: true,
                data: _.get(data, 'data') || [],
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [uri, ..._.values(params)]
    );

    useEffect(() => {
        if (!notFetch) fetch();
    }, [fetch, notFetch]);

    return {
        ...state,
        setState,
        fetch,
    };
};
