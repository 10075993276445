import { Form, Select } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCustomers } from '../redux/actions/customer';

const SelectCustomer = ({ getCustomers, customers }) => {
    useEffect(() => {
        getCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form.Item
            name="customerId"
            label="Khách hàng"
            rules={[
                {
                    required: true,
                    message: 'Vui lòng chọn!',
                },
            ]}
        >
            <Select
                allowClear
                placeholder="Chọn khách hàng"
                optionFilterProp="label"
                showSearch
                dropdownMatchSelectWidth={false}
                loading={customers.isFetching}
                options={_.map(customers.data, (item) => ({
                    value: item.id,
                    label: item.lastName + ' ' + item.firstName,
                }))}
                disabled={true}
                // {...props}
            />
        </Form.Item>
    );
};

const mapStateToProps = (state) => {
    return {
        customers: state.Customer,
    };
};
export default connect(mapStateToProps, {
    getCustomers,
})(SelectCustomer);
