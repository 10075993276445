const ButtonReload = ({
    text = '',
    className,
    icon = <i className="fas fa-sync-alt mr-1"></i>,
    onClick,
}) => {
    return (
        <button
            className={`${className} mr-3 inline-block px-2 py-2 font-bold text-center bg-gradient-to-tl from-red-500 to-yellow-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white`}
            onClick={() => onClick && onClick()}
        >
            {icon}
            {text}
        </button>
    );
};
export default ButtonReload;
