import { Col, DatePicker, Form, Input, message, Modal, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useState } from 'react';
import { createEmployee, updateEmployee } from '../../redux/actions/employee';
// import {formatter} from "../../helpers/utility"
import dayjs from 'dayjs';
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import Subdivision from '../../components/Subdivision';
import TextArea from 'antd/es/input/TextArea';

const EmployeeDetail = ({
    setShowModal,
    showModal,
    currentData = {},
    createEmployee,
    updateEmployee,
    fetchEmployee,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isView = currentData.id;

    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
                dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
            };

            setLoading(true);
            const data = !isView
                ? await createEmployee(transformData)
                : await updateEmployee({
                      ...transformData,
                      id: currentData.id,
                      accountId: currentData.account.id,
                  });
            if (data) {
                message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                !isView && setShowModal();
                fetchEmployee();
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal
                title={
                    isView
                        ? 'Chỉnh sửa thông tin nhân viên'
                        : 'Thêm mới nhân viên'
                }
                open={showModal}
                centered
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        // initialValues={{
                        //     ...currentData,
                        //     dateOfBirth: dayjs(currentData.dateOfBirth),
                        // }}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                        name="lastName"
                                        label="Họ"
                                    >
                                        <Input
                                            className="w-full"
                                            // formatter={formatter}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="firstName"
                                        label="Tên"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền  !',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="phoneNumber"
                                        label="Số điện thoại"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item name="email" label="Email">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="identityCard"
                                        label="Số Căn cước công dân"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                {currentData && (
                                    <Col xs={24} md={12} className="mb-1">
                                        <Form.Item
                                            label="Ngày sinh"
                                            name="dateOfBirth"
                                        >
                                            <DatePicker
                                                className="w-full"
                                                format={'DD/MM/YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row gutter={16}>
                                <Subdivision form={form} data={currentData} />
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className="mb-1">
                                    <Form.Item
                                        name="detailedAddress"
                                        label="Chi tiết địa chỉ"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className="mb-1">
                                    <Form.Item name="note" label="Ghi chú">
                                        <TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default connect(null, {
    createEmployee,
    updateEmployee,
})(EmployeeDetail);
