import _ from 'lodash';
import { message, Modal, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings';
import ButtonCustom from '../../components/elements/ButtonCustom';
import OrderDetail from '../Order/OrderDetail/orderDetail';

const ListNeedCareOrder = () => {
    const [showModal, setShowModal] = useState();
    const [OrderId, setOrderId] = useState();
    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            setOrderId(record.orderId);
            setShowModal(true);
        }
    };
    const [orderNeedCare, setOrderNeedCare] = useState([]);
    const fetchData = async () => {
        const { data } = await client().get(
            `${apiUrl}/OrderNeedCare/GetAllOrderNeedCare`
        );
        setOrderNeedCare(data);
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có muốn dòng này ?',
            onOk: async () => {
                const { data } = await client().delete(
                    `${apiUrl}/OrderNeedCare/DeleteOrderNeedCareById/?id=${record.id}`
                );
                if (data) message.success('Đã xóa');
                fetchData();
            },
            okButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
            cancelButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
        });
    };
    const colorPriority = (priority) => {
        switch (priority) {
            case 1:
                return ' bg-gradient-to-tl from-[#17ad37] to-[#98ec2d]';
            case 2:
                return ' bg-gradient-to-tl from-[#ca8a04] to-[#fbc400]';
            case 3:
                return ' bg-gradient-to-tl from-[#e11d48] to-[#f472b6]';
            default:
                return ' bg-gradient-to-tl from-[#e11d48] to-[#f472b6]';
        }
    };
    const columns = [
        {
            title: 'Tên khách hàng',
            dataIndex: 'customer',
            key: 'customer',
            sorter: (a, b) =>
                a.customer.firstName.length - b.customer.firstName.length,
            sortDirections: ['descend'],
            render: (customer) => (
              <Tooltip
              title={
                  customer.phoneNumber1
                      ? customer.phoneNumber1
                      : customer.phoneNumber2
              }
              placement="top"
          >
                    <span>{customer.lastName + ' ' + customer.firstName}</span>
          </Tooltip>

            ),
        },
        {
            title: 'Độ ưu tiên',
            dataIndex: 'priority',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.priority - b.priority,
            render: (priority) => (
                <span
                    className={`py-[0.4em] px-[0.7em] text-md rounded-[.45rem] inline-block whitespace-nowrap text-center  align-baseline font-bold uppercase leading-none text-white ${colorPriority(
                        priority
                    )}`}
                >
                    {priority}
                </span>
            ),
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            render: (note) => (
              <Tooltip title={note} placement="right">
                <div className="mx-auto truncate max-w-52 ">{note}</div>
                </Tooltip>

            ),
        },
        {
            title: 'Action',
            width: 50,
            key: 'delete',
            render: (record) => {
                return (
                    <Tooltip title={`Xoá dòng này`} placement="right">
                        <ButtonCustom
                            onClick={(e) => handleDelete(e, record)}
                            textColor={'text-red-400'}
                            icon={<i className="fas fa-trash-alt"></i>}
                        />
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            {!_.isEmpty(orderNeedCare) && (
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                    <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0">
                        <div className="flex-none w-7/12 max-w-full px-3 mt-0 lg:w-1/2 lg:flex-none">
                            <h6>Danh sách đơn hàng cần quan tâm</h6>
                        </div>
                    </div>
                    <Table
                        className="text-green-500"
                        columns={columns}
                        dataSource={orderNeedCare}
                        onRow={(record, index) => ({
                            onClick: (event) =>
                                handleRowClick(record, index, event),
                        })}
                    />
                </div>
            )}
            {showModal && (
                <OrderDetail
                    OrderId={OrderId}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    setOrderId={setOrderId}
                />
            )}
        </>
    );
};
export default ListNeedCareOrder;
