import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getPartners() {
    return async (dispatch) => {
        dispatch({ type: types.PARTNER_FETCH_START });
        await client()
            .get(`${apiUrl}/Partner/GetAllPartner`)
            .then((data) => {
                dispatch({
                    type: types.PARTNER_FETCH_SUCCESS,
                    data: data.data || [],
                });
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: types.PARTNER_FETCH_FAILURE,
                    errors: err,
                });
                throw err;
            });
    };
}

export function createPartner(data) {
    return async (dispatch, state) => {
        const { data: result } = await client().post(
            `${apiUrl}/Partner/AddPartner`,
            data
        );
        return result;
    };
}

export function updatePartner(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/Partner/UpdatePartnerById/${data.id}`,
            data
        );
        return result;
    };
}
