import {
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { createOrder, updateOrder } from '../../../redux/actions/order';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import client from '../../../helpers/client';
import apiUrl from '../../../settings';
import ButtonReload from '../../../components/elements/ButtonReload';
import DetailList from './detailList';
import SelectEmployee from '../../../components/SelectEmployee';
import { CARD_TYPES } from '../../../settings/constant';
import { useData } from '../../../helpers/hooks';
import { PAYMENT_METHOD } from '../../../settings/constant';

const OrderDetail = ({
  setShowModal,
  showModal,
  currentData,
  createOrder,
  updateOrder,
  fetchOrder,
  setCurrentData,
  OrderId,
  setOrderId,
}) => {
  const isView = OrderId;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data } = useData(`/Province/GetAllProvince`);
  const [detail, setDetail] = useState({
    saleDate: dayjs(),
    orderDetail: [],
  });
  const fetchOrderDetail = async () => {
    if (isView) {
      setLoading(true);
      const { data } = await client().get(
        `${apiUrl}/Order/GetOrderById/${OrderId}`
      );
      setLoading(false);
      form.setFieldsValue({
        ...data,
        saleDate: dayjs(data?.saleDate),
        customerFullName:
          data?.customer?.lastName + ' ' + data?.customer?.firstName,
        customerPhoneNumber: data?.customer?.phoneNumber1,
        phoneNumber2: data?.customer?.phoneNumber2,
        addressFull: isView && {
          province: [
            data?.customer?.provinceId,
            data?.customer?.districtId,
            data?.customer?.wardId,
          ],
          street: data?.customer?.detailedAddress,
        },
      });
      setDetail({ ...data, saleDate: dayjs(data.saleDate) });
    } else {
      form.setFieldsValue(currentData);
    }
  };

  const handleOk = () => {
    form.validateFields().then(async (values) => {
      const allForm = {
        ...values,
        orderDetail: detail.orderDetail,
        saleDate: dayjs(detail.saleDate).format('YYYY-MM-DD'),
        provinceId: values?.addressFull?.province[0],
        districId: values?.addressFull?.province[1],
        wardId: values?.addressFull?.province[2],
        address: values?.addressFull?.street,
      };
      if (detail.orderDetail[0].paymentmethod === PAYMENT_METHOD[1].id) {
        delete allForm.orderDetail[0].bankInfoId;
        delete allForm.orderDetail[0].bankInfo;
      }
      delete allForm.addressFull;
      setLoading(true);
      const data = !isView
        ? await createOrder(allForm)
        : await updateOrder({
          ...allForm,
          id: OrderId,
          customerId: detail.customer?.id,
        });
      if (data) {
        message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
        isView && setShowModal(false);
        setCurrentData();
        fetchOrder();
      }
      setLoading(false);
    });
  };

  const handleCancel = () => {
    if (!isView) {
      const values = form.getFieldsValue();
      setCurrentData({ ...values, saleDate: detail.saleDate });
    }
    setOrderId();
    setShowModal(false);
  };

  useEffect(() => {
    fetchOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    form.resetFields();
  };

  const handleSaleDateChange = (value) => {
    setDetail({ ...detail, saleDate: value });
  };
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
        -1
    );
  return (
    <div>
      <Modal
        title={!isView ? 'Thêm mới đơn hàng' : 'Thông tin đơn hàng'}
        open={showModal}
        centered
        width={1280}
        onCancel={handleCancel}
        footer={
          <>
            <div>
              <ButtonReload onClick={resetForm} text="Reset" />
              <ButtonAdd
                icon={
                  isView ? (
                    <SaveOutlined
                      style={{ fontSize: '15px' }}
                    />
                  ) : (
                    <PlusOutlined
                      style={{ fontSize: '15px' }}
                    />
                  )
                }
                onClick={handleOk}
                text={isView ? 'Lưu' : 'Tạo'}
              />
            </div>
          </>
        }
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical">
            <div className="mt-6">
              <Row gutter={16}>
                <Col xs={24} md={8} className="mb-1">
                  <Form.Item
                    label="Ngày lên đơn "
                    initialValue={dayjs()}
                  >
                    <DatePicker
                      onChange={handleSaleDateChange}
                      className="w-full"
                      format={'DD/MM/YYYY'}
                      value={detail.saleDate}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} className="mb-1">
                  <Form.Item
                    name="customerFullName"
                    label="Tên Khách Hàng"
                    rules={[
                      {
                        required: true,
                        message:
                          'Vui lòng nhập họ tên khách hàng!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} className="mb-1">
                  <SelectEmployee />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={8} className="mb-1">
                  <Form.Item
                    name="cardType"
                    label="Loại giấy tờ đính kèm"
                  >
                    <Select
                      allowClear
                      placeholder="Chọn loại giấy tờ"
                      optionFilterProp="label"
                      showSearch
                      dropdownMatchSelectWidth={false}
                      options={_.map(
                        CARD_TYPES,
                        (item) => ({
                          value: item.id,
                          label: item.name,
                        })
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} className="mb-1">
                  <Form.Item
                    name="customerPhoneNumber"
                    label="Số Điện Thoại"
                    rules={[
                      {
                        required: true,
                        message:
                          'Vui lòng nhập số điện thoại của bạn!',
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col md={8} className="mb-1">
                  <Form.Item
                    name="phoneNumber2"
                    label="Số Điện Thoại 2"
                    rules={[
                      {
                        required: true,
                        message:
                          'Vui lòng nhập số điện thoại của bạn!',
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col md={24} className="mb-1">
                  <Form.Item label="Địa chỉ">
                    <Input.Group compact>
                      <Form.Item
                        name={[
                          'addressFull',
                          'province',
                        ]}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message:
                              'Vui lòng chọn !',
                          },
                        ]}
                      >
                        <Cascader
                          style={{ width: '40%' }}
                          showSearch={{ filter }}
                          placeholder="Tỉnh Thành / Quận / Phường(xã)"
                          options={_.map(
                            data,
                            (item) => ({
                              value: item.id,
                              label: item.name,
                              children: _.map(
                                item.district,
                                (item) => ({
                                  value: item.id,
                                  label: item.name,
                                  children:
                                    _.map(
                                      item.ward,
                                      (
                                        item
                                      ) => ({
                                        value: item.id,
                                        label: item.name,
                                        children:
                                          [],
                                      })
                                    ),
                                })
                              ),
                            })
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={['addressFull', 'street']}
                        noStyle
                      >
                        <Input
                          style={{ width: '60%' }}
                          placeholder="Tên đường"
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}></Row>
              <Row gutter={16}>
                <Col xs={24} md={24} className="mb-1">
                  <Form.Item name="note" label="Ghi chú" rules={[
                    {
                      validator: async (_, value) => {
                        if (!value || value.split('\n').length <= 4) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Ghi chú không được vượt quá 4 dòng.'));
                      },
                    }
                  ]}>
                    <TextArea />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
          <Row gutter={16}>
            <Col xs={24} md={24} className="mb-1">
              <Spin spinning={loading}>
                {!loading && (
                  <DetailList
                    isView={isView}
                    setDetail={setDetail}
                    detail={detail}
                  />
                )}
              </Spin>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
};

export default connect(null, {
  createOrder,
  updateOrder,
})(OrderDetail);
