import { Col, Form, Input, Row } from 'antd';
import { useState } from 'react';
import client from '../helpers/client';
import apiUrl from '../settings';

const CustomerCreate = ({ onChange }) => {
    // eslint-disable-next-line no-unused-vars
    const [isExist, setIsExist] = useState();
    const checkCustomer = async (e) => {
        const phone = e.target.value;
        await client()
            .get(
                `${apiUrl}/Customer/FindCustomerByPhoneNumber?phoneNumber=${phone}`
            )
            .then(({ data }) => {
                if (data === false) {
                    return setIsExist(false);
                } else {
                    onChange(data);
                }
            });
    };
    return (
        <Row gutter={16}>
            <Col xs={24} md={12} className="mb-1">
                <Form.Item
                    name="phoneNumber"
                    label="Số điện thoại"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền !',
                        },
                    ]}
                >
                    <Input onChange={checkCustomer} />
                </Form.Item>
            </Col>
            <Col xs={24} md={12} className="mb-1">
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền !',
                        },
                    ]}
                    name="fullName"
                    label="Họ và tên"
                >
                    <Input className="w-full" />
                </Form.Item>
            </Col>
            <Col xs={24} md={12} className="mb-1">
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền !',
                        },
                    ]}
                    name="detailedAddress"
                    label="Địa chỉ"
                >
                    <Input className="w-full" />
                </Form.Item>
            </Col>
        </Row>
    );
};
export default CustomerCreate;
