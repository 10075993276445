import _ from 'lodash';
import { Input, message, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import { DeleteOutlined } from '@ant-design/icons';
import CustomerDetail from './customerDetail';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { ACTION } from '../../settings/constant';
import ButtonReload from '../../components/elements/ButtonReload';
import { useSelector } from 'react-redux';
import { GIAM_DOC } from '../../helpers/roles';
const Customer = ({ router }) => {
    const { roles } = useSelector((state) => state.auth);
    const [Customers, setCustomers] = useState();
    const [filterCustomers, setFilterCustomers] = useState();
    const [currentData, setCurrentData] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchCustomer = async () => {
        setLoading(true);
        const { data } = await client().get(`${apiUrl}/Customer`);
        setLoading(false);
        setCustomers(data);
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            setCurrentData({
                ...record,
                // dateOfBirth: dayjs(record.dateOfBirth),
            });
        }
    };

    const handleAdd = () => {
        setCurrentData(ACTION.CREATE);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có muốn xóa khách hàng này ?',
            onOk: async () => {
                const { data } = await client().delete(
                    `${apiUrl}/Customer/id?id=${record.id}`
                );
                if (data) message.success('Đã xóa');
                if (filterCustomers)
                    setFilterCustomers(
                        filterCustomers.filter((item) => item.id !== record.id)
                    );
                fetchCustomer();
            },
            okButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
            cancelButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
        });
    };

    const search = (e) => {
        const value = e.target.value;
        const filterTable = Customers.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        if (!value) setFilterCustomers();
        else {
            setFilterCustomers(filterTable);
        }
    };

    const columns = [
        {
            title: 'Họ tên',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text, record) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {text + ' ' + record.firstName}
                    </div>
                );
            },
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'detailedAddress',
            key: 'detailedAddress',
            render: (text, record) => (
                <div
                    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                >
                    {text}
                </div>
            ),
            width: 200,
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber1',
            key: 'phoneNumber1',
        },
        {
            title: 'Thành phố',
            dataIndex: 'province',
            key: 'province',
            render: (province) => {
                return _.get(province, 'name');
            },
        },
        {
            title: 'Quận',
            dataIndex: 'district',
            key: 'district',
            render: (district) => {
                return _.get(district, 'name');
            },
        },
        {
            title: 'Phường',
            dataIndex: 'ward',
            key: 'ward',
            render: (ward) => {
                return _.get(ward, 'name');
            },
        },
        {
            title: 'Action',
            key: 'action',
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        handleDelete(event, record);
                    },
                };
            },
            render: () => (
                <span>
                    {roles[0] === GIAM_DOC && (
                        <DeleteOutlined className="text-rose-600 text-lg" />
                    )}
                </span>
            ),
        },
    ];

    return (
        <div>
            <div className="text-blue-500 font-bold">Khách hàng</div>
            <div className="my-4">
                <div className="flex flex-wrap  justify-between mb-4">
                    <Input
                        className="w-64 mt-3"
                        placeholder="Lọc ..."
                        onChange={search}
                    />
                    <div className="mt-3">
                        <ButtonReload
                            onClick={() => fetchCustomer()}
                            className="mr-4"
                            text="Refresh"
                        />
                        <ButtonAdd onClick={handleAdd} />
                    </div>
                </div>

                <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                    <Table
                        scroll={{ x: 'max-content' }}
                        loading={loading}
                        className="text-green-500"
                        columns={columns}
                        dataSource={
                            !filterCustomers ? Customers : filterCustomers
                        }
                        rowClassName="cursor-pointer"
                        onRow={(record, index) => ({
                            onClick: (event) =>
                                handleRowClick(record, index, event),
                        })}
                    />
                </div>
            </div>

            {(currentData || currentData === ACTION.CREATE) && (
                <CustomerDetail
                    setShowModal={setCurrentData}
                    showModal={currentData}
                    currentData={currentData}
                    fetchCustomer={fetchCustomer}
                    filterCustomers={filterCustomers}
                />
            )}
        </div>
    );
};
export default withRouter(Customer);
