import { Checkbox, Input, message, Modal, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import { DeleteOutlined } from '@ant-design/icons';
import ProductDetail from './productDetail';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { ACTION } from '../../settings/constant';
import { ButtonDowload } from '../../components/elements/ButtonDowload';
import dayjs from 'dayjs';
import _ from 'lodash';

const Product = ({ router }) => {
    //   const { params } = router;
    //   const { id } = params;
    const [products, setProducts] = useState();
    const [filterProducts, setFilterProducts] = useState();

    const [currentData, setCurrentData] = useState(false);
    const fetchProducts = async () => {
        const { data } = await client().get(`${apiUrl}/Product`);
        setProducts(data);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            // handle row click event
            setCurrentData(record);
        }
    };

    const handleAdd = () => {
        setCurrentData(ACTION.CREATE);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có muốn xóa sản phẩm này ?',
            onOk: async () => {
                const { data } = await client().delete(
                    `${apiUrl}/Product/id?id=${record.id}`
                );
                if (data) message.success('Đã xóa');
                if (filterProducts)
                    setFilterProducts(
                        filterProducts.filter((item) => item.id !== record.id)
                    );
                fetchProducts();
            },
            okButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
            cancelButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
        });
    };

    const search = (e) => {
        const value = e.target.value;
        const filterTable = products.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        if (!value) setFilterProducts();
        else {
            setFilterProducts(filterTable);
        }
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a?.name.localeCompare(b?.name),
            title: () => {
                return <div className="editable-row">Tên Xe</div>;
            },
            render: (name, { isSale }) => {
                return (
                    <div className={`${isSale && 'text-red-500'}`}>{name}</div>
                );
            },
        },
        {
            title: () => {
                return <div className="editable-row">Màu Xe</div>;
            },
            dataIndex: 'color',
            key: 'color',
            sorter: (a, b) => a.color && a?.color.localeCompare(b?.color),
            render: (color) => {
                return <div>{color}</div>;
            },
        },
        {
            title: () => {
                return <div className="editable-row">Nhà cung cấp</div>;
            },
            dataIndex: 'goodsReceiptNoteSupplier',
            key: 'goodsReceiptNoteSupplier',
            sorter: (a, b) =>
                a?.goodsReceiptNoteSupplier?.name &&
                a?.goodsReceiptNoteSupplier?.name.localeCompare(
                    b?.goodsReceiptNoteSupplier?.name
                ),
            render: (goodsReceiptNoteSupplier) => {
                return (
                    <div className="editable-row">
                        {goodsReceiptNoteSupplier?.name}
                    </div>
                );
            },
        },
        {
            title: () => {
                return <div className="editable-row">Số Khung</div>;
            },
            dataIndex: 'frameNumber',
            key: 'frameNumber',
        },
        {
            title: () => {
                return <div className="editable-row">Số Máy</div>;
            },
            dataIndex: 'engineNumber',
            key: 'engineNumber',
        },
        {
            title: () => {
                return <div className="editable-row">Số hóa đơn</div>;
            },
            dataIndex: 'numberContracts',
            key: 'numberContracts',
        },
        {
            title: () => {
                return <div className="editable-row">Ngày Đăng Kiểm</div>;
            },
            dataIndex: 'registrationDate',
            key: 'registrationDate',
            render: (text) => {
                return text ? dayjs(text).format('DD/MM/YYYY') : '';
            },
        },

        {
            title: () => {
                return <div className="editable-row">Số bảo hành</div>;
            },
            dataIndex: 'warrantyNumber',
            key: 'warrantyNumber',
            sorter: (a, b) => a.warrantyNumber - b.warrantyNumber,
            render: (text) => {
                if (text) {
                    return <Tag color={'green'}>{'Đã có'.toUpperCase()}</Tag>;
                } else {
                    return <Tag color={'red'}>{'Chưa có'.toUpperCase()}</Tag>;
                }
            },
        },
        {
            title: () => {
                return <div className="editable-row">Đăng kiểm</div>;
            },
            dataIndex: 'registry',
            key: 'registry',
            sorter: (a, b) => a.registry - b.registry,
            render: (text) => {
                if (text) {
                    return (
                        <Tag color={'green'}>
                            {'Đã đăng kiểm'.toUpperCase()}
                        </Tag>
                    );
                } else {
                    return <Tag color={'red'}>{'Chưa'.toUpperCase()}</Tag>;
                }
            },
        },
        {
            title: () => {
                return <div className="editable-row">Trạng thái kho</div>;
            },
            dataIndex: 'isSaled',
            key: 'isSaled',
            sorter: (a, b) => a.isSaled - b.isSaled,
            render: (text) => {
                if (text) {
                    return <Tag color={'red'}>{'Đã bán'.toUpperCase()}</Tag>;
                } else {
                    return (
                        <Tag color={'green'}>{'Còn hàng'.toUpperCase()}</Tag>
                    );
                }
            },
        },
        {
            title: 'Action',
            key: 'action',
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        handleDelete(event, record);
                    },
                };
            },
            render: () => (
                <span>
                    <DeleteOutlined className="text-rose-600 text-lg" />
                </span>
            ),
        },
    ];

    return (
        <div>
            <div className="text-blue-500 font-bold">Sản phẩm</div>
            <div className="my-4">
                <div className="flex flex-wrap justify-between mb-4">
                    <Input
                        className="w-64 mt-3"
                        placeholder="Lọc ..."
                        onChange={search}
                    />
                    <div className="mt-3">
                        <ButtonDowload />
                        <ButtonAdd onClick={handleAdd} />
                    </div>
                </div>

                <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                    <Table
                        scroll={{ x: 'max-content' }}
                        className="text-green-500"
                        columns={columns}
                        dataSource={!filterProducts ? products : filterProducts}
                        rowClassName="cursor-pointer"
                        onRow={(record, index) => ({
                            onClick: (event) =>
                                handleRowClick(record, index, event),
                        })}
                    />
                </div>
            </div>

            {(currentData || currentData === ACTION.CREATE) && (
                <ProductDetail
                    setShowModal={setCurrentData}
                    showModal={currentData}
                    currentData={currentData}
                    fetchProducts={fetchProducts}
                    filterProducts={filterProducts}
                />
            )}
        </div>
    );
};
export default withRouter(Product);
