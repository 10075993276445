import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectManufacturer = ({ ...props }) => {
    const { data, loading } = useData(`/Manufacturer`);

    return (
        <Form.Item name="manufacturerId" label="Nhà cung cấp">
            <Select
                aria-autocomplete="none"
                placeholder="Chọn hãng"
                allowClear
                showSearch
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                optionFilterProp="label"
                {...props}
            />
        </Form.Item>
    );
};

export default SelectManufacturer;
