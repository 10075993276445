import { Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useState } from 'react';
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import SelectRoles from '../../components/SelectRoles';
import client from '../../helpers/client';
import apiUrl from '../../settings';

const AccountForm = ({ setShowModal, showModal, currentData = {} }) => {
    console.log('🚀 ~ file: accountForm.js:21 ~ currentData:', currentData);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isView = currentData.id;

    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
                employeeId: currentData.employeeId,
            };
            setLoading(true);
            console.log(
                '🚀 ~ file: accountForm.js:22 ~ form.validateFields ~ transformData:',
                transformData
            );
            const data = await client().put(
                `${apiUrl}/Role/UpdateUserRole`,
                transformData
            );

            if (data) {
                message.success(
                    'Đã cập nhật vai trò mới cho tài khoản ' + values.userName
                );
                setShowModal(false);
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal
                title={
                    isView
                        ? 'Chỉnh sửa thông tin nhân viên'
                        : 'Thêm mới nhân viên'
                }
                open={showModal}
                centered
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={{
                            ...currentData,
                        }}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="userName"
                                        label="Tài khoản"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="phoneNumber"
                                        label="Số điện thoại"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item name="email" label="Email">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <SelectRoles />
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default AccountForm;
