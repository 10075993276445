
import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getSuppliers() {
    return async dispatch => {
      dispatch({ type: types.SUPPLIER_FETCH_START })
     await client().get(`${apiUrl}/Supplier`).then(data => {
        dispatch({
            type: types.SUPPLIER_FETCH_SUCCESS,
            data: data.data || [] ,
          })
          return data;
      }).catch(err => {
        dispatch({
            type: types.SUPPLIER_FETCH_FAILURE,
            errors: err
        });
        throw err;
      })
    };
  }

  export function createSupplier(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
      const {data: result} =  await client().post(`${apiUrl}/Supplier`, data);
      return result
    };
  }

  export function updateSupplier(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
      const {data: result} =  await client().put(`${apiUrl}/Supplier/id?id=${data.id}`, data);
      return result
    };
  }