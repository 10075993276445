import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Authentication from './components/api-authorization/Authentication';
import { Layout } from './components/Layout';
import './custom.css';
import LoginPage from './pages/LoginPage';
import Report from './pages/Report';
import Invoice from './pages/Invoice';

function App(props) {
    return (
        <Routes>
            {AppRoutes.map((route, index) => {
                const { element, requireAuth, ...rest } = route;
                return (
                    <Route
                        key={index.toString()}
                        {...rest}
                        element={
                            requireAuth ? (
                                <Authentication
                                    {...rest}
                                    element={<Layout>{element}</Layout>}
                                />
                            ) : (
                                <Layout>{element}</Layout>
                            )
                        }
                    />
                );
            })}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/report/:id" element={<Report />} />
            <Route path="/invoice/:id" element={<Invoice />} />
        </Routes>
    );
}

App.propTypes = {};

export default App;
