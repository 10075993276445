import authService from '../../components/api-authorization/AuthorizeService';
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_MESSAGE,
} from '../../settings/action';

export const login = (dispatch, value) => {
    return authService.loginUser(value).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data.employeeInfo, roles: data.roles },
            });
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const logout = (dispatch, navigate) => {
    authService.logout();
    navigate();
    dispatch({
        type: LOGOUT,
    });
};
