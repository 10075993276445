import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Tag,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getManufacturer } from '../../redux/actions/manufacturer';
import { createProduct, updateProduct } from '../../redux/actions/product';
// import {formatter} from "../../helpers/utility"
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const ProductDetail = ({
    setShowModal,
    showModal,
    currentData = {},
    getManufacturer,
    manufaturer,
    createProduct,
    updateProduct,
    fetchProducts,
    quickAdd,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [registrationDate, setRegistrationDate] = useState(
        _.get(currentData, 'registrationDate')
            ? dayjs(currentData.registrationDate)
            : null
    );

    const isView = currentData.id;
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            setLoading(true);

            const data = !isView
                ? await createProduct({
                      ...values,
                      registrationDate:
                          registrationDate &&
                          dayjs(registrationDate).format('YYYY-MM-DD'),
                  })
                : await updateProduct({
                      ...values,
                      id: currentData.id,
                      registrationDate:
                          registrationDate &&
                          dayjs(registrationDate).format('YYYY-MM-DD'),
                  });
            if (data) {
                message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                !isView && setShowModal();
                fetchProducts();
                if (quickAdd) await quickAdd(data.id);
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const handleSaleDateChange = (value) => {
        setRegistrationDate(value);
    };

    useEffect(() => {
        getManufacturer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updateText = (isSale) => {
        if (isSale) {
            return (
                <p>
                    Chỉnh sửa thông tin sản phẩm
                    <Tag color="red"> Đã bán</Tag>
                </p>
            );
        }
        return ' Chỉnh sửa thông tin sản phẩm ';
    };
    return (
        <div>
            <Modal
                title={
                    isView
                        ? updateText(currentData.isSaled)
                        : 'Thêm mới sản phẩm'
                }
                open={showModal}
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={currentData}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="name"
                                        label="Tên sản phẩm"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Vui lòng điền tên sản phẩm !',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item name="price" label="Giá">
                                        <InputNumber
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )
                                            }
                                            className="w-full"
                                            // formatter={formatter}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="frameNumber"
                                        label="Số khung"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="engineNumber"
                                        label="Số kỹ thuật"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item name="color" label="Màu">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Ngày nhận đăng kiểm"
                                        initialValue={dayjs()}
                                    >
                                        <DatePicker
                                            onChange={handleSaleDateChange}
                                            className="w-full"
                                            format={'DD/MM/YYYY'}
                                            value={registrationDate}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="numberContracts"
                                        label="Số hóa đơn"
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: 'Vui lòng nhập!',
                                        //     },
                                        // ]}
                                    >
                                        <Input className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        valuePropName="checked"
                                        name="registry"
                                        label=""
                                    >
                                        <Checkbox>Đăng kiểm</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        valuePropName="checked"
                                        name="warrantyNumber"
                                        label=""
                                    >
                                        <Checkbox>Số bảo hành</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        manufaturer: state.Manufaturer,
    };
};

export default connect(mapStateToProps, {
    getManufacturer,
    createProduct,
    updateProduct,
})(ProductDetail);
