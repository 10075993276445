import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectWard = ({ districtId, ...props }) => {
    const { data, loading } = useData(
        districtId && `/Province/GetAllWardByDistrictId/${districtId}`
    );
    return (
        <Form.Item name="wardId" label="Xã / Phường / Thị Trấn">
            <Select
                aria-autocomplete="none"
                placeholder="Chọn Xã / Phường / Thị Trấn"
                allowClear
                showSearch
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                optionFilterProp="label"
                {...props}
            />
        </Form.Item>
    );
};

export default SelectWard;
