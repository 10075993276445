/* eslint-disable import/no-anonymous-default-export */
import { currencyReducer } from './currency';
import Manufaturer from './Manufaturer';
import Product from './Product';
import Employee from './Employee';
import Customer from './Customer';
import Order from './Order';
import BankInfo from './BankInfo';
import Message from './message';
import auth from './auth';
import Partner from './Partner';
import LicensePS from './LicensePS';
export default {
    currencyReducer,
    Manufaturer,
    Product,
    Employee,
    Customer,
    Order,
    BankInfo,
    Message,
    auth,
    Partner,
    LicensePS,
};
