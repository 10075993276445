import Home from './components/Home';
import BankInfo from './pages/BankInfo';
import Customer from './pages/Customer';
import District from './pages/District';
import Employee from './pages/Employee';
import GoodsReceiptNote from './pages/GoodsReceiptNote';
import InfomationUser from './pages/InfomationUser';
import LicensePlateService from './pages/LicensePlateService';
import Manufactural from './pages/Manufacture';
import Order from './pages/Order';
import Partner from './pages/Partner';
import Product from './pages/Product';
import ProvicePage from './pages/Province';
import Report from './pages/Report';
import Supplier from './pages/Supplier';

const AppRoutes = [
    {
        path: '/',
        element: <Home />,
        requireAuth: true,
    },
    {
        path: '/province',
        element: <ProvicePage />,
        requireAuth: true,
    },
    {
        path: '/manufacturer',
        element: <Manufactural />,
        requireAuth: true,
    },
    {
        path: '/district/:id',
        exact: true,
        element: <District />,
        requireAuth: true,
    },
    {
        path: '/product',
        exact: true,
        element: <Product />,
        requireAuth: true,
    },
    {
        path: '/goods-receipt-note',
        exact: true,
        element: <GoodsReceiptNote />,
        requireAuth: true,
    },
    {
        path: '/employee',
        exact: true,
        element: <Employee />,
        requireAuth: true,
    },
    {
        path: '/customer',
        exact: true,
        element: <Customer />,
        requireAuth: true,
    },
    {
        path: '/order',
        exact: true,
        element: <Order />,
        requireAuth: true,
    },
    {
        path: '/bankinfo',
        exact: true,
        element: <BankInfo />,
        requireAuth: true,
    },
    {
        path: '/partner',
        exact: true,
        element: <Partner />,
        requireAuth: true,
    },
    {
        path: '/license',
        exact: true,
        element: <LicensePlateService />,
        requireAuth: true,
    },
    {
        path: '/profile',
        exact: true,
        element: <InfomationUser />,
        requireAuth: true,
    },
    {
        path: '/supplier',
        exact: true,
        element: <Supplier />,
        requireAuth: true,
    },
];

export default AppRoutes;
