import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getOrders() {
    return async (dispatch) => {
        dispatch({ type: types.ORDER_FETCH_START });
        await client()
            .get(`${apiUrl}/Order`)
            .then((data) => {
                dispatch({
                    type: types.ORDER_FETCH_SUCCESS,
                    data: data.data || [],
                });
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: types.ORDER_FETCH_FAILURE,
                    errors: err,
                });
                throw err;
            });
    };
}

export function createOrder(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().post(`${apiUrl}/Order`, data);
        return result;
    };
}

export function updateOrder(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/Order/UpdateOrderById/${data.id}/${data.customerId}`,
            data
        );
        return result;
    };
}
