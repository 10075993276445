import { useEffect, useState } from 'react';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import { DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import '../style.css';
import dayjs from 'dayjs';
import FormAddDetail from './formAddDetail';
import { Table, Tag } from 'antd';

const DetailList = ({ detail, setDetail, isView }) => {
    console.log('🚀 ~ file: detailList.js:11 ~ DetailList ~ detail:', detail);
    const [openAddDetail, setOpenAddDetail] = useState();
    const [currentData, setCurrentData] = useState({});

    const [list, setList] = useState(detail.orderDetail);
    const columns = [
        {
            title: () => {
                return <div className="editable-row">Sản phẩm</div>;
            },
            dataIndex: 'product',
            key: 'product',
            render: (product) => _.get(product, 'name'),
        },
        {
            title: () => {
                return <div className="editable-row">Số Khung</div>;
            },
            dataIndex: 'product',
            key: 'product',
            render: (product) => _.get(product, 'frameNumber'),
        },
        {
            title: () => {
                return <div className="editable-row">Số Máy</div>;
            },
            dataIndex: 'product',
            key: 'product',
            render: (product) => _.get(product, 'engineNumber'),
        },
        {
            dataIndex: 'priceOrder',
            key: 'priceOrder',
            title: () => {
                return <div className="editable-row">Giá xe</div>;
            },
            render: (priceOrder) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {priceOrder !== 0
                            ? `${priceOrder}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </div>
                );
            },
        },
        {
            dataIndex: 'actuallyReceived',
            key: 'actuallyReceived',
            title: () => {
                return <div className="editable-row">Đưa trước</div>;
            },
            render: (actuallyReceived) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {actuallyReceived !== 0
                            ? `${actuallyReceived}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </div>
                );
            },
        },
        {
            dataIndex: 'debt',
            key: 'debt',
            title: () => {
                return <div className="editable-row">Nợ cửa hàng</div>;
            },
            render: (debt) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {debt !== 0
                            ? `${debt}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                              ' VNĐ'
                            : 0}
                    </div>
                );
            },
        },
        {
            dataIndex: 'paymentmethod',
            key: 'paymentmethod',
            title: () => {
                return <div className="editable-row">Hình thức thanh toán</div>;
            },
            render: (paymentmethod) =>
                paymentmethod === 0 ? (
                    <Tag color={'green'}>{'Ngân Hàng'.toUpperCase()}</Tag>
                ) : (
                    <Tag color={'volcano'}>{'Tiền Mặt'.toUpperCase()}</Tag>
                ),
        },
        {
            title: () => {
                if (list[0]?.paymentmethod === 0) {
                    return <div className="editable-row">Ngân Hàng</div>;
                }
            },
            dataIndex: 'bankInfo',
            key: 'bankInfo',
            render: (bankInfo) => _.get(bankInfo, 'name'),
            visible: (paymentmethod) => paymentmethod !== 0,
        },
        {
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            title: () => {
                return <div className="editable-row">Ngày giao cavet</div>;
            },
            render: (deliveryDate) =>
                deliveryDate ? dayjs(deliveryDate).format('DD/MM/YYYY') : '',
        },
        {
            dataIndex: 'npDeliveryDate',
            key: 'npDeliveryDate',
            title: () => {
                return <div className="editable-row">Ngày giao biển số</div>;
            },
            render: (npDeliveryDate) => {
                return npDeliveryDate
                    ? dayjs(npDeliveryDate).format('DD/MM/YYYY')
                    : '';
            },
        },
        {
            title: 'Action',
            key: 'action',
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        handleDelete(event, record);
                    },
                };
            },
            render: () => (
                <span>
                    {!isView && (
                        <DeleteOutlined className="text-rose-600 text-lg" />
                    )}
                </span>
            ),
        },
    ];

    const handleAddDetail = () => {
        setOpenAddDetail(true);
    };

    const handleRowClick = (record, index) => {
        setCurrentData({ ...record, id: index, orderID: detail.id });
        setOpenAddDetail(true);
    };

    const handleDelete = (e, record, index) => {
        e.stopPropagation();
        setList(
            _.filter(
                list,
                (item) => item.orderDetailId !== record.orderDetailId
            )
        );
    };

    useEffect(() => {
        setDetail({ ...detail, orderDetail: list });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    return (
        <div>
            <div className="flex justify-end">
                {list.length === 0 && (
                    <ButtonAdd onClick={handleAddDetail} text="Thêm" />
                )}
            </div>
            <Table
                size="small"
                scroll={{ x: 'max-content' }}
                dataSource={[...list]}
                columns={columns}
                rowClassName={() => 'editable-row cursor-pointer'}
                onRow={(record, index) => ({
                    onClick: (event) => handleRowClick(record, index, event),
                })}
            />
            {openAddDetail && (
                <FormAddDetail
                    isViewOrder={isView}
                    currentData={currentData}
                    openAddDetail={openAddDetail}
                    setOpenAddDetail={setOpenAddDetail}
                    setList={setList}
                    list={list}
                    setCurrentData={setCurrentData}
                />
            )}
        </div>
    );
};

export default DetailList;
