import { PasswordForm } from './PasswordForm';
import React from 'react';

function InfomationUser(props) {
    return (
        <div className="w-full max-w-full px-3 lg:flex-0 shrink-0">
            {/* <div className="relative flex flex-col min-w-0 mt-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="p-6 mb-0 rounded-t-2xl">
                    <h5 className="dark:text-white">Thông tin cơ bản </h5>
                </div>
            </div> */}
            <PasswordForm />
        </div>
    );
}

export default InfomationUser;
