import React, { useState } from 'react';
import { NavbarToggler } from 'reactstrap';
import routes from '../helpers/routes';
import LoginMenu from './api-authorization/LoginMenu';
import './NavMenu.css';

export const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };
    const GetActiveRoute = (routes) => {
        console.log(
            '🚀 ~ file: NavMenu.js:13 ~ GetActiveRoute ~ routes:',
            routes
        );
        let activeRoute = 'Default Brand Text';
        const location = window.location;
        for (let i = 0; i < routes.length; i++) {
            console.log(
                '🚀 ~ file: NavMenu.js:21 ~ GetActiveRoute ~ routes[i]:',
                routes[i]
            );
            if (location.pathname === routes[i].path) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };
    return (
        <nav
            className="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all shadow-none duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start"
            navbar-scroll="true"
        >
            <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
                <nav>
                    <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
                        <li className="leading-normal text-sm">
                            <div className="opacity-50 text-slate-700">
                                Danh sách
                            </div>
                        </li>
                        <li
                            className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']"
                            aria-current="page"
                        >
                            {GetActiveRoute(routes)}
                        </li>
                    </ol>
                    {/* <h6 className="mb-0 font-bold capitalize"></h6> */}
                </nav>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">
                    <LoginMenu></LoginMenu>
                </ul>
            </div>
        </nav>
    );
};
