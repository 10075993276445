import React from 'react';
import { Form, message, Modal, Row, Spin, Col, Input, Cascader } from 'antd';
import { useState } from 'react';
import ButtonCancel from '../../components/elements/ButtonCancel';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { createPartner, updatePartner } from '../../redux/actions/partner';
import { connect } from 'react-redux';
import { useData } from '../../helpers/hooks';
import TextArea from 'antd/es/input/TextArea';

function PartnerFormDetail({
    setShowModal,
    showModal,
    currentData = {},
    createPartner,
    updatePartner,
    fetchPartner,
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isView = currentData.id;
    const handleCancel = () => {
        setShowModal(false);
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
            };

            setLoading(true);
            const data = !isView
                ? await createPartner(transformData)
                : await updatePartner({
                      ...transformData,
                      id: currentData.id,
                  });
            if (data) {
                message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                setShowModal();
                fetchPartner();
            }
            setLoading(false);
        });
    };
    return (
        <Modal
            title={isView ? 'Chỉnh sửa thông tin đối tác' : 'Thêm mới đối tác '}
            open={showModal}
            centered
            width={800}
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd
                            icon={
                                isView ? (
                                    <SaveOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                ) : (
                                    <PlusOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                )
                            }
                            onClick={handleOk}
                            text={isView ? 'Lưu' : 'Tạo'}
                        />
                    </div>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    initialValues={{
                        ...currentData,
                        fullName:
                            isView &&
                            currentData.lastName + ' ' + currentData.firstName,
                    }}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        {' '}
                        <Col xs={24} md={12} className="mb-1">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                                name="fullName"
                                label="Họ và tên"
                            >
                                <Input
                                    className="w-full"
                                    // formatter={formatter}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} className="mb-1">
                            <Form.Item
                                name="phoneNumber"
                                label="Số điện thoại"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} className="mb-1">
                            <Form.Item name="note" label="Ghi chú">
                                <TextArea />
                            </Form.Item>
                        </Col>
                        {/* <Row gutter={16}>
                            <Subdivision form={form} data={currentData} />
                        </Row> */}
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
const mapStateToProps = (state) => {
    return {
        partner: state.Partner,
    };
};
export default connect(mapStateToProps, {
    createPartner,
    updatePartner,
})(PartnerFormDetail);
