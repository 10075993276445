import { Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import client from '../helpers/client';
import { convertNumberToWords } from '../helpers/utility';
import apiUrl from '../settings';
import { CARD_TYPES } from '../settings/constant';
import './report-css.css';

function Report(props) {
    const { id } = useParams();
    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const fetchOrderDetail = async () => {
        setLoading(true);
        const { data } = await client().get(
            `${apiUrl}/Order/GetOrderById/${id}`
        );
        console.log('🚀 ~ file: Report.js:14 ~ fetchOrderDetail ~ data:', data);
        if (data) {
            console.log(
                '🚀 ~ file: Report.js:20 ~ fetchOrderDetail ~ data:',
                data
            );
            setOrder(data);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchOrderDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Spin spinning={loading}>
            <div id="page-container">
                <div id="pf1" className="pf w0 h0" data-page-no="1">
                    <div className="pc pc1 w0 h0 opened">
                        <div className="c x0 y1 w2 h0">
                            <div className="t m0 x1 h2 y2 ff1 fs0 fc0 sc0 ls4 ws5">
                                CTY{' '}
                                <span className="ff2">
                                    TNHH MTV THƯƠNG MẠI DỊCH VỤ SƠN ĐỨC PHÁT
                                </span>
                            </div>
                            <div className="t m0 x2 h2 y3 ff1 fs0 fc0 sc0 ls4 ws5">
                                HOTLINE: 0933 790 456{' '}
                                <span className="ff2 ls0">–</span> 0328 265 7 10{' '}
                            </div>
                            <div className="t m0 x3 h3 y4 ff3 fs1 fc0 sc0 ls4 ws5">
                                ĐỊA CHỈ: SỐ 66, TỔ 6, ẤP 5, AN PHƯỚC, LONG
                                THÀNH, ĐỒNG NAI
                            </div>
                            <div className="t m0 x4 h3 y5 ff4 fs1 fc0 sc0 ls4 ws5">
                                {' '}
                            </div>
                            <div className="t m0 x5 h4 y6 ff2 fs2 fc0 sc0 ls4 ws5">
                                HỢP ĐỒNG MUA BÁN XE
                            </div>
                            <div className="t m0 x6 h3 y7 ff4 fs1 fc0 sc0 ls4 ws5">
                                NGÀY{' '}
                                <span className="ls5 ws0">
                                    {' '}
                                    {dayjs(order?.saleDate).date()}
                                </span>{' '}
                                THÁNG {dayjs(order?.saleDate).month() + 1}{' '}
                                <span className="ff3 ws1">NĂM</span>{' '}
                                {dayjs(order?.saleDate).year()}{' '}
                            </div>
                            <div className="t m0 x7 h5 y8 ff1 fs1 fc0 sc0 ls1 ws5">
                                {' '}
                                <span className="ff2 ls4">
                                    HÌNH THỨC MUA
                                    <span className="ff3">
                                        :{' '}
                                        {order?.orderDetail[0].paymentmethod ===
                                        0
                                            ? 'NGÂN HÀNG'
                                            : 'TIỀN MẶT'}
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 y9 ff2 fs1 fc0 sc0 ls4 ws5">
                                HỌ VÀ TÊN KHÁCH
                                <span className="ff4">
                                    : {order?.customer?.lastName.toUpperCase()}{' '}
                                    {order?.customer?.firstName.toUpperCase()}
                                </span>
                            </div>
                            <div className="t m0 x4 h5 ya ff2 fs1 fc0 sc0 ls4 ws5">
                                ĐỊA CHỈ
                                <span className="ff4">
                                    :{' '}
                                    <span className="ff3">
                                        {order?.customer?.ward?.name.toUpperCase()}
                                        {', '}
                                        {order?.customer?.district?.name.toUpperCase()}
                                        {', '}
                                        {order?.customer?.province?.name.toUpperCase()}
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 yb ff2 fs1 fc0 sc0 ls4 ws5">
                                SỐ ĐIỆN THOẠI
                                <span className="ff4 ls6">
                                    :
                                    <span className="ls4">
                                        {order?.customer?.phoneNumber1}
                                        {order?.customer?.phoneNumber2 && (
                                            <>
                                                <span className="ff3 ws1">
                                                    –
                                                </span>
                                                {order?.customer?.phoneNumber2}
                                            </>
                                        )}
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 yc ff1 fs1 fc0 sc0 ls4 ws5">
                                TÊN XE
                                <span className="ff4">
                                    :{' '}
                                    {order?.orderDetail[0]?.product?.name.toUpperCase()}
                                    <span className="_ _1"></span>
                                    <span className="_ _2"> </span>
                                    {(order?.orderDetail[0]?.note ||
                                        order?.orderDetail[0]?.product
                                            ?.color) && (
                                        <>
                                            <span className="ff1 ls7 ws2">
                                                MÀU
                                            </span>
                                            <span className="ff3">
                                                :{' '}
                                                {order?.orderDetail[0]?.note
                                                    ? order?.orderDetail[0]
                                                          ?.note
                                                    : order?.orderDetail[0]?.product?.color.toUpperCase()}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </div>
                            <div className="t m0 x4 h5 yd ff2 fs1 fc0 sc0 ls4 ws5">
                                SỐ KHUNG
                                <span className="ff4 ls6">
                                    :
                                    <span className="ls4">
                                        {
                                            order?.orderDetail[0]?.product
                                                ?.frameNumber
                                        }
                                        <span className="_ _4"></span>
                                        <span className="_ _2"> </span>
                                        <span className="_ _2"> </span>
                                        <span className="_ _2"> </span>
                                        <span className="ff2">SỐ MÁY</span>:
                                        {
                                            order?.orderDetail[0]?.product
                                                ?.engineNumber
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 ye ff2 fs1 fc0 sc0 ls4 ws5">
                                KHOẢN VAY NGÂN HÀNG (NẾU CÓ):
                                <span className="ff4">
                                    {' '}
                                    <span className="ls5 ws0">
                                        {' '}
                                        {order?.orderDetail[0]?.debtBank}
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h2 yf ff2 fs0 fc0 sc0 ls8 ws3">
                                NỢ
                                <span className="ff1 ls2 ws5">
                                    {' '}
                                    <span className="ff2 ls4">CỬA HÀNG</span>
                                    <span className="ls3">
                                        :
                                        <span className="ff4 ls4">
                                            {' '}
                                            {`${order?.orderDetail[0]?.debt}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                            {' VNĐ'}
                                            <span className="_ _3"> </span>{' '}
                                            <span className="_ _5"> </span>
                                            <span className="ff2">
                                                THỰC NHẬN
                                            </span>{' '}
                                            :{' '}
                                            {`${order?.orderDetail[0]?.actuallyReceived}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                            {' VNĐ'}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 y10 ff2 fs1 fc0 sc0 ls4 ws5">
                                NỢ BẰNG CHỮ
                                <span className="ff4 ls6">
                                    :{' '}
                                    <span className="ls4">
                                        {' '}
                                        <span className="ff3">
                                            {convertNumberToWords(
                                                order?.orderDetail[0]?.debt
                                            )}
                                        </span>
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h5 y11 ff2 fs1 fc0 sc0 ls4 ws5">
                                GIẤY TỜ CỬA HÀNG GIỮ(PHỤC VỤ CHO VIỆC LÀM BIỂN
                                SỐ CÀ VẸT):
                                <span className="ff4">
                                    {
                                        CARD_TYPES.find(
                                            (e) => e.id === order?.cardType
                                        )?.name
                                    }
                                </span>
                            </div>
                            {/* <div className="t m0 x4 h-[70px] y12 ff1 fs1 fc0 sc0 ls4 ws5">
                                GHI CHÚ
                                <span className="ff4">: {order?.note} </span>
                            </div> */}
                            <div className="t m0 x4 h5 y12 ff2 fs1 fc0 sc0 ls4 ws5">
                                QUÝ KHÁCH HÀNG VUI LÒNG THANH TOÁN ĐỦ SỐ TIỀN
                                CÒN NỢ BÊN CỬA HÀNG KHI NHẬN{' '}
                            </div>
                            <div className="t m0 x4 h5 y13 ff2 fs1 fc0 sc0 ls4 ws5">
                                BIỂN SỐ.
                            </div>
                            <div className="t m0 x4 h6 y14 ff5 fs3 fc0 sc0 ls4 ws5">
                                MỘT SỐ LƯU Ý TRONG THỜI GIAN ĐỢI BIỂN SỐ NẾU CÓ
                                PHÁT SINH MONG QUÝ KHÁCH THÔNG CẢM
                                <span className="ff6"> </span>
                            </div>
                            <div className="t m0 x4 h7 y15 ff7 fs1 fc0 sc0 ls4 ws5">
                                1.BIỂN SỐ THƯỜNG CÓ TRƯỚC CÀ VẸT
                                <span className="ff8"> </span>
                            </div>
                            <div className="t m0 x4 h7 y16 ff7 fs1 fc0 sc0 ls4 ws5">
                                2.BIỂN SỐ VÀ CÀ VẸT LÀ DO TRỰC TIẾP CHÍNH QUYỀN
                                C.A CẤP XÃ, PHƯỜNG HOẶC HUYỆN
                                <span className="ff8"> </span>CÁC TỈNH{' '}
                            </div>
                            <div className="t m0 x4 h7 y17 ff7 fs1 fc0 sc0 ls4 ws5">
                                THEO ĐỊA CHỈ CỦA KHÁCH HÀNG CUNG CẤP THỜI GIAN
                                CẤP BIỂN CÓ THỂ SỚM HOẶC TRỄ HƠN THỜI{' '}
                            </div>
                            <div className="t m0 x4 h7 y18 ff7 fs1 fc0 sc0 ls4 ws5">
                                GIAN HẸN 1<span className="ff8 ws1">-</span>3
                                TUẦN TÙY THEO LỊCH LÀM VIỆC CỦA CÔNG AN .
                                <span className="ff8"> </span>
                            </div>
                            <div className="t m0 x4 h7 y19 ff7 fs1 fc0 sc0 ls4 ws5">
                                3.MỘT SỐ NƠI THANH TRA ĐỘT XUÁT CÓ THỂ PHẢI XÉT
                                XE VÀ XÉT CHỦ
                                <span className="ff8"> </span>
                            </div>
                            <div className="t m0 x4 h7 y1a ff7 fs1 fc0 sc0 ls4 ws5">
                                4.ĐỊA CHỈ THƯỜNG TRÚ KHÁCH HÀNG CUNG CẤP PHẢI
                                KHỚP VỚI ĐỊA CHỈ Ở NẾU KHÁCH HÀNG CHUYỂN KHẨU{' '}
                            </div>
                            <div className="t m0 x4 h7 y1b ff7 fs1 fc0 sc0 ls4 ws5">
                                HOẶC THÔNG TIN BỊ SAI LỆCH TRÊN HỆ THỐNG SẼ
                                KHÔNG RA BIỂN SỐ ĐƯỢC
                                <span className="ff8"> </span>
                            </div>
                            <div className="t m0 x4 h7 y1c ff7 fs1 fc0 sc0 ls4 ws5">
                                5.CÁC DÒNG XE SƠN MÀU LẠI THỜI GIAN NHẬN CÀ VẸT
                                SẼ LÂU
                                <span className="ff8"> </span>HƠN KHOẢNG 2-4 THÁNG
                                <span className="ff8"> </span>
                            </div>
                            <div className="t m0 x4 h7 y1d ff8 fs1 fc0 sc0 ls4 ws5">
                                6.NÊN 4-
                                <span className="ff7">
                                    6 THÁNG BẢO DƯỠNG XE 1 LẦN TẠI CỬA HÀNG ĐỂ
                                    ĐƯỢC THEO DÕI TỐT NHẤT TÌNH TRẠNG{' '}
                                </span>
                            </div>
                            <div className="t m0 x4 h7 y1e ff8 fs1 fc0 sc0 ls9 ws4">
                                XE
                            </div>
                            <div className="underline t m0 x4 h6 y20 ff1 fs1 fc0 sc0 ls4 ws5">
                            GHI CHÚ
                                <span className=" ff4">: {order?.note} </span>
                            </div>
                            <div className="t m0 x4 h8 y21 ff6 fs1 fc0 sc0 lsa ws5">
                                {' '}
                                <span className="ls4">
                                    KHÁCH HÀNG <span className="_ _6"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="_ _2"> </span>{' '}
                                    <span className="ff5">
                                        ĐẠI DIỆN CỬA HÀNG
                                        <span className="ff6"> </span>
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h7 y22 ff7 fs1 fc0 sc0 ls4 ws5">
                                (KÝ VÀ GHI RÕ HỌ TÊN)
                                <span className="ff8 ls1">
                                    {'      '}
                                    <span className="ff7 ls4">
                                        (KÝ VÀ ĐÓNG MỌC)
                                        <span className="ff8"> </span>
                                    </span>
                                </span>
                            </div>
                            <div className="t m0 x4 h3 y23 ff4 fs1 fc0 sc0 ls4 ws5">
                                {' '}
                                <span className="_ _8"> </span>{' '}
                            </div>
                        </div>
                    </div>
                    <div
                        className="pi"
                        data-data='{"ctm":[1.000000,0.000000,0.000000,1.000000,0.000000,0.000000]}'
                    ></div>
                </div>
            </div>
        </Spin>
    );
}

Report.propTypes = {};

export default Report;
