import { Col } from 'antd';
import { useEffect, useState } from 'react';
import SelectDistrict from './SelectDistrict';
import SelectProvince from './SelectProvince';
import SelectWard from './SelectWard';

const Subdivision = ({ form, data }) => {
    const [state, setState] = useState({});

    useEffect(() => {
        setState(data);
    }, [data]);

    useEffect(() => {
        form.setFieldsValue(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const setS = (newS) => {
        setState((s) => ({
            ...s,
            ...newS,
        }));
    };

    return (
        <>
            <Col xs={24} md={10}>
                <SelectProvince
                    onChange={(provinceId) =>
                        setS({
                            districtId: undefined,
                            wardId: undefined,
                            provinceId,
                        })
                    }
                />
            </Col>
            <Col xs={24} md={7}>
                <SelectDistrict
                    onChange={(districtId) =>
                        setS({
                            districtId,
                            wardId: undefined,
                        })
                    }
                    provinceId={state.provinceId}
                />
            </Col>
            <Col xs={24} md={7}>
                <SelectWard
                    onChange={(wardId) => setS({ wardId })}
                    districtId={state.districtId}
                />
            </Col>
        </>
    );
};

export default Subdivision;
