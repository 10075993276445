import React, { useState, useEffect, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth';

function LoginMenu() {
    const auth = useSelector((state) => state.auth);
    const { user, isLoggedIn } = auth;
    const [userName, setUserName] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const subscription = authService.subscribe(() => populateState());
        populateState();
        return () => {
            authService.unsubscribe(subscription);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function populateState() {
        setIsAuthenticated(isLoggedIn);
        setUserName(user && user?.lastName + ' ' + user?.firstName);
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function authenticatedView(userName, profilePath, logoutPath) {
        return (
            <Fragment>
                <NavItem className="flex items-center">
                    <NavLink
                        tag={Link}
                        className="block px-0 py-2 font-semibold transition-all ease-nav-brand text-[.875rem] text-[#67748e] dark:text-white"
                        to={profilePath}
                    >
                        <i
                            className="fa fa-user sm:mr-1"
                            aria-hidden="true"
                        ></i>
                        <span className="hidden sm:inline">{userName}</span>
                    </NavLink>
                </NavItem>
                <NavItem className="flex items-center px-4">
                    <div
                        className="block px-0 py-2 font-semibold transition-all ease-nav-brand text-[.875rem] text-[#67748e] dark:text-white cursor-pointer"
                        onClick={() => logout(dispatch, navigate(logoutPath))}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                        <span className="hidden sm:inline">Logout</span>
                    </div>
                </NavItem>
            </Fragment>
        );
    }

    function anonymousView(registerPath, loginPath) {
        return (
            <>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={registerPath}>
                        Register
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/login">
                        <i
                            className="fa fa-user sm:mr-1"
                            aria-hidden="true"
                        ></i>
                        <span className="hidden sm:inline">Sign In</span>
                    </NavLink>
                </NavItem>
            </>
        );
    }

    if (!isAuthenticated) {
        const registerPath = `${ApplicationPaths.Register}`;
        const loginPath = `${ApplicationPaths.Login}`;
        return anonymousView(registerPath, loginPath);
    } else {
        const profilePath = `${ApplicationPaths.Profile}`;
        const logoutPath = `${ApplicationPaths.Login}`;
        return authenticatedView(userName, profilePath, logoutPath);
    }
}

export default LoginMenu;
