import _ from 'lodash';
import { Input, message, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import { ACTION } from '../../settings/constant';
import ButtonAdd from '../../components/elements/ButtonAdd';
import PartnerFormDetail from './PartnerFormDetail';
import { useSelector } from 'react-redux';
import { GIAM_DOC } from '../../helpers/roles';
import { useNavigate } from 'react-router-dom';
const columns = [
    {
        title: 'Họ tên',
        dataIndex: 'lastName',
        key: 'lastName',
        render: (text, record) => {
            return (
                <div className="leading-normal text-sm font-semibold">
                    {text + ' ' + record.firstName}
                </div>
            );
        },
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
];
const Partner = ({ router }) => {
    const [partners, setPartners] = useState();
    const [filterPartners, setFilterPartners] = useState();
    const [currentData, setCurrentData] = useState(false);
    const fetchPartner = async () => {
        const { data } = await client().get(`${apiUrl}/Partner/GetAllPartner`);
        setPartners(data);
    };
    const { roles } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            // handle row click event
            setCurrentData({
                ...record,
            });
        }
    };

    const handleAdd = () => {
        setCurrentData(ACTION.CREATE);
    };
    useEffect(() => {
        if (roles[0] !== GIAM_DOC) {
            navigate('/');
        }
        fetchPartner();
    }, []);
    const search = (e) => {
        const value = e.target.value;
        const filterTable = partners.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        if (!value) setFilterPartners();
        else {
            setFilterPartners(filterTable);
        }
    };
    return (
        <div>
            <div className="text-blue-500 font-bold">Đối tác dịch vụ</div>
            {roles[0] === GIAM_DOC && (
                <div className="my-4">
                    <div className="flex flex-wrap justify-between mb-4">
                        <Input
                            className="w-64 mt-4"
                            placeholder="Lọc ..."
                            onChange={search}
                        />
                        <div className="mt-4">
                            <ButtonAdd onClick={handleAdd} />
                        </div>
                    </div>
                </div>
            )}
            <Table
                dataSource={!filterPartners ? partners : filterPartners}
                columns={columns}
                pagination={{ pageSize: 10 }}
                onRow={(record, index) => ({
                    onClick: (event) =>
                        roles[0] === GIAM_DOC &&
                        handleRowClick(record, index, event),
                })}
            />

            {(currentData || currentData === ACTION.CREATE) && (
                <PartnerFormDetail
                    setShowModal={setCurrentData}
                    showModal={currentData}
                    currentData={currentData}
                    fetchPartner={fetchPartner}
                />
            )}
        </div>
    );
};
export default withRouter(Partner);
