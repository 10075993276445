import { Col, Form, Input, message, Modal, Rate, Row, Spin } from 'antd';
import React, { useState } from 'react';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import ButtonCancel from '../../../components/elements/ButtonCancel';
import { PlusOutlined } from '@ant-design/icons';
import client from '../../../helpers/client';
import apiUrl from '../../../settings';
import TextArea from 'antd/es/input/TextArea';
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
function ModalOrderNeedCare({
    OrderId,
    setShowModal,
    showModal,
    currentData = {},
}) {
    const [value, setValue] = useState(3);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
                orderId: OrderId,
            };
            setLoading(true);
            await client()
                .post(`${apiUrl}/OrderNeedCare/AddOrderNeedCare`, transformData)
                .then((data) => {
                    if (data.request.status === 200) {
                        message.success('Thêm thành công !');
                    }
                });
            handleCancel();
            setLoading(false);
        });
    };
    return (
        <Modal
            title="Thêm vào danh sách đơn hàng cần quan tâm"
            open={showModal}
            centered
            width={800}
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd
                            icon={<PlusOutlined style={{ fontSize: '15px' }} />}
                            onClick={handleOk}
                            text="Tạo"
                        />
                    </div>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form form={form} initialValues={currentData} layout="vertical">
                    <div className="mt-6">
                        <Row gutter={16}>
                            <Col md={24} className="mb-1">
                                <Form.Item
                                    name="priority"
                                    label="Độ ưu tiên"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng điền !',
                                        },
                                    ]}
                                >
                                    <Rate
                                        tooltips={desc}
                                        onChange={setValue}
                                        value={value}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={24} className="mb-1">
                                <Form.Item
                                    name="note"
                                    label="Ghi chú"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng điền !',
                                        },
                                    ]}
                                >
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
}

ModalOrderNeedCare.propTypes = {};

export default ModalOrderNeedCare;
