import _ from 'lodash';
import { Input, message, Modal, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import { DeleteOutlined } from '@ant-design/icons';
import EmployeeDetail from './employeeDetail';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { ACTION } from '../../settings/constant';
import ExcelUploader from '../../components/elements/ExcelUploader';
import ButtonCustom from '../../components/elements/ButtonCustom';
import AccountForm from './accountForm';
import { useSelector } from 'react-redux';
import { GIAM_DOC } from '../../helpers/roles';
import { useNavigate } from 'react-router-dom';
const Employee = ({ router }) => {
    const [Employees, setEmployees] = useState();
    const [filterEmployees, setFilterEmployees] = useState();
    const [openForm, setOpenForm] = useState(false);
    const [currentData, setCurrentData] = useState(false);
    const [currentDataAccount, setCurrentDataAccount] = useState(false);
    const { roles } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const fetchEmployee = async () => {
        const { data } = await client().get(`${apiUrl}/Employee`);
        setEmployees(data);
    };
    const fetchRoleDefault = async (accountId) => {
        if (!accountId) {
            return;
        }
        const { data } = await client().get(
            `${apiUrl}/Role/GetRoleByUserId?id=${accountId}`
        );
        return data;
    };

    useEffect(() => {
        if (roles[0] !== GIAM_DOC) {
            navigate('/');
        }
        fetchEmployee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            // handle row click event
            setCurrentData({
                ...record,
                dateOfBirth: record.dateOfBirth && dayjs(record.dateOfBirth),
            });
        }
    };

    const handleAdd = () => {
        setCurrentData(ACTION.CREATE);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có muốn xóa nhân viên này ?',
            onOk: async () => {
                const { data } = await client().delete(
                    `${apiUrl}/Employee/id?id=${record.id}`
                );
                if (data) message.success('Đã xóa');
                if (filterEmployees)
                    setFilterEmployees(
                        filterEmployees.filter((item) => item.id !== record.id)
                    );
                fetchEmployee();
            },
            okButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
            cancelButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
        });
    };

    const search = (e) => {
        const value = e.target.value;
        const filterTable = Employees.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        if (!value) setFilterEmployees();
        else {
            setFilterEmployees(filterTable);
        }
    };
    const closeFormAccount = () => {
        setCurrentDataAccount(false);
        setOpenForm(false);
    };

    const openFormAccount = async (e, record) => {
        e.stopPropagation();
        fetchRoleDefault();
        let role = await fetchRoleDefault(record?.account?.id);
        let currentDataAccount = {
            ...record.account,
            roleId: role?.id,
            employeeId: record.id,
        };
        setCurrentDataAccount(currentDataAccount);
        setOpenForm(true);
    };
    const columns = [
        {
            title: 'Họ tên',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (text, record) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {text + ' ' + record.firstName}
                    </div>
                );
            },
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'detailedAddress',
            key: 'detailedAddress',
            render: (text, record) => (
                <div
                    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
                >
                    {text}
                </div>
            ),
            width: 200,
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Ngày sinh',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            render: (text) => {
                return text ? dayjs(text).format('DD/MM/YYYY') : '';
            },
        },
        {
            title: 'Thành phố',
            dataIndex: 'province',
            key: 'province',
            render: (province) => {
                return _.get(province, 'name');
            },
        },
        {
            title: 'Quận',
            dataIndex: 'district',
            key: 'district',
            render: (district) => {
                return _.get(district, 'name');
            },
        },
        {
            title: 'Phường',
            dataIndex: 'ward',
            key: 'ward',
            render: (ward) => {
                return _.get(ward, 'name');
            },
        },
        {
            title: 'Tài khoản',
            dataIndex: 'account',
            key: 'account',
            render: (account) => {
                return _.get(account, 'userName');
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) =>
                roles[0] === GIAM_DOC && (
                    <>
                        <Tooltip title={'Xoá Nhân Viên'} placement="right">
                            <ButtonCustom
                                onClick={(e) => handleDelete(e, record)}
                                textColor={'text-red-400'}
                                icon={<i className="fas fa-trash-alt"></i>}
                            />
                        </Tooltip>
                        <Tooltip
                            title={'Thông tin tài khoản'}
                            placement="right"
                        >
                            <ButtonCustom
                                onClick={(e) => openFormAccount(e, record)}
                                textColor={'text-green-400'}
                                icon={<i className="fas fa-id-card"></i>}
                            />
                        </Tooltip>
                    </>
                ),
        },
    ];

    return (
        <div>
            <div className="text-blue-500 font-bold">Nhân viên</div>
            <div className="my-4">
                <div className="flex flex-wrap justify-between mb-4">
                    <Input
                        className="w-64 mt-4"
                        placeholder="Lọc ..."
                        onChange={search}
                    />
                    <div className="mt-4">
                        <ButtonAdd onClick={handleAdd} />
                        <ExcelUploader
                            url="api/ImportExcel/ReadEmployeeFile"
                            title="nhân viên"
                            reloadData={() => fetchEmployee()}
                        />
                    </div>
                </div>

                <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                    <Table
                        scroll={{ x: 'max-content' }}
                        className="text-green-500"
                        columns={columns}
                        dataSource={
                            !filterEmployees ? Employees : filterEmployees
                        }
                        rowClassName="cursor-pointer"
                        onRow={(record, index) => ({
                            onClick: (event) =>
                                handleRowClick(record, index, event),
                        })}
                    />
                </div>
            </div>

            {(currentData || currentData === ACTION.CREATE) &&
                !openForm &&
                !currentDataAccount && (
                    <EmployeeDetail
                        setShowModal={setCurrentData}
                        showModal={currentData}
                        currentData={currentData}
                        fetchEmployee={fetchEmployee}
                        filterEmployees={filterEmployees}
                    />
                )}
            {openForm && currentDataAccount && (
                <AccountForm
                    currentData={currentDataAccount}
                    showModal={openForm}
                    setShowModal={closeFormAccount}
                />
            )}
        </div>
    );
};
export default withRouter(Employee);
