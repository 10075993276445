import { Cascader, Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { createSupplier, updateSupplier } from '../../redux/actions/supplier';
// import {formatter} from "../../helpers/utility"
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import Subdivision from '../../components/Subdivision';
import TextArea from 'antd/es/input/TextArea';
import { useData } from '../../helpers/hooks';
import _ from 'lodash';
// import SupplierCreate from '../../components/SupplierCreate';

const SupplierDetail = ({
    setShowModal,
    showModal,
    currentData = {},
    createSupplier,
    updateSupplier,
    fetchSupplier,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState(currentData);
    const [isView, setIsView] = useState(defaultData.id);
    const { data } = useData(`/Province/GetAllProvince`);

    useEffect(() => {
        setIsView(defaultData.id);
    }, [defaultData]);

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                // const form = {
                //     ...values,
                //     provinceId: values?.addressFull?.province[0],
                //     districId: values?.addressFull?.province[1],
                //     wardId: values?.addressFull?.province[2],
                // };
                setLoading(true);
                const data = !isView
                    ? await createSupplier(values)
                    : await updateSupplier({ ...values, id: currentData.id });
                if (data) {
                    message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                    !isView && setShowModal();
                    fetchSupplier();
                }
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const filter = (inputValue, path) =>
        path.some(
            (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
        );

    return (
        <div>
            <Modal
                title={
                    isView
                        ? 'Chỉnh sửa thông tin nhà cung cấp'
                        : 'Thêm mới nhà cung cấp'
                }
                open={showModal}
                centered
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={defaultData}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            <>
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        className="mb-1"
                                    >
                                        <Form.Item
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: 'Vui lòng điền !',
                                            //     },
                                            // ]}
                                            name="name"
                                            label="Tên"
                                        >
                                            <Input className="w-full" />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        className="mb-1"
                                    >
                                        <Form.Item
                                            name="phoneNumber"
                                            label="Số điện thoại"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền !',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col
                                        xs={24}
                                        sm={12}
                                        md={12}
                                        className="mb-1"
                                    >
                                        <Form.Item name="email" label="Email">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Subdivision
                                        form={form}
                                        data={defaultData}
                                    />
                                </Row>
                            </>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default connect(null, {
    createSupplier,
    updateSupplier,
})(SupplierDetail);
