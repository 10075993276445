import React, { useState } from 'react';
import { InputNumber, message, Modal, Typography } from 'antd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import ButtonAdd from '../../components/elements/ButtonAdd';
import client from '../../helpers/client';
import apiUrl from '../../settings';

function PaymentModal({ isModalOpen, onClose, id, fetchData }) {
    const [amount, setAmount] = useState();

    const handleOk = async () => {
        await client()
            .put(
                `${apiUrl}/LicensePlateService/PaymentForPartner/${id}/${amount}`
            )
            .then((data) => {
                if (data.request.status === 200) {
                    message.success('Cập nhật thành công !');
                    fetchData && fetchData();
                    onClose && onClose();
                }
            });
    };
    const handleCancel = () => {
        onClose();
    };
    return (
        <Modal
            title="Thanh toán cho đối tác"
            open={isModalOpen}
            centered
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Huỷ" />
                        <ButtonAdd
                            icon={<i className="fas fa-check-double mr-2"></i>}
                            onClick={handleOk}
                            text="Xác nhận"
                        />
                    </div>
                </>
            }
        >
            <Typography>Số tiền thanh toán</Typography>
            <InputNumber
                formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                onChange={setAmount}
                className="w-full"
            />
        </Modal>
    );
}

PaymentModal.propTypes = {};

export default PaymentModal;
