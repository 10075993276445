import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getEmployees() {
    return async (dispatch) => {
        dispatch({ type: types.EMPLOYEE_FETCH_START });
        await client()
            .get(`${apiUrl}/Employee`)
            .then((data) => {
                dispatch({
                    type: types.EMPLOYEE_FETCH_SUCCESS,
                    data: data.data || [],
                });
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: types.EMPLOYEE_FETCH_FAILURE,
                    errors: err,
                });
                throw err;
            });
    };
}

export function createEmployee(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().post(
            `${apiUrl}/Employee`,
            data
        );
        return result;
    };
}

export function updateEmployee(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/Employee/id?id=${data.id}`,
            data
        );
        return result;
    };
}
export function updateRoleForUser(data) {
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/Role/UpdateUserRole`,
            data
        );
        return result;
    };
}
