import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectProvince = (props) => {
    const { data, loading } = useData(`/Province/GetAllProvince`);
    return (
        <Form.Item
            name="provinceId"
            label="Thành Phố / Tỉnh"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Vui lòng chọn!',
            //     },
            // ]}
        >
            <Select
                allowClear
                placeholder="Chọn Thành phố / Tỉnh"
                optionFilterProp="label"
                showSearch
                dropdownMatchSelectWidth={false}
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                {...props}
            />
        </Form.Item>
    );
};

export default SelectProvince;
