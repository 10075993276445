import _ from 'lodash';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings';
import { Link } from 'react-router-dom';

const ProvicePage = () => {
    const [province, setProvince] = useState([]);
    const fetchData = async () => {
        const { data } = await client().get(
            `${apiUrl}/Province/GetAllProvince`
        );
        setProvince(data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            title: 'Tên thành phố',
            dataIndex: 'name',
            key: 'name',
            render: (text) => (
                <Link to="/district/test">
                    <span>{text}</span>
                </Link>
            ),
        },
        {
            title: 'Phân loại',
            dataIndex: 'divisionType',
        },
    ];

    return (
        <div>
            <div className="text-blue-500 font-bold">Thành phố</div>
            {!_.isEmpty(province) && (
                <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                    <Table
                        className="text-green-500"
                        columns={columns}
                        dataSource={province}
                    />
                </div>
            )}
        </div>
    );
};
export default ProvicePage;
