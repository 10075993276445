import { Checkbox, DatePicker, Input, message, Modal, Table, Tag, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import OrderDetail from './OrderDetail/orderDetail';
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonReload from '../../components/elements/ButtonReload';
import ExcelUploader from '../../components/elements/ExcelUploader';
import ButtonCustom from '../../components/elements/ButtonCustom';
import ModalOrderNeedCare from '../NeedCareOrder/ModalOrderNeedCare';
import { getFullName } from '../../helpers/utility';
import ModalLicensePlateService from '../LicensePlateService/ModalLicensePlateService';
import SelectEmployee from './SelectEmployee';
import ModalSentNumberBike from '../LicensePlateService/ModalSentNumberBike';
import SelectBank from '../../components/SelectBank';
import ExportToExcel from './ExportToExcel';
var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const headers = [
  'Ngày',
  'Tên Khách Hàng',
  "Nhân viên",
  'SDT 1',
  'Địa chỉ',
  'Xe',
  'Màu',
  'Số Máy',
  'Số Khung',
  'Giá',
  'Đưa trước',
  'Vay Lại',
  'Nợ của hàng',
  'Ghi Chú',
  'Ngân Hàng'
];

const { RangePicker } = DatePicker;
const Order = ({ router }) => {
  const [Orders, setOrders] = useState();
  const [execlData, setExcelData] = useState();
  const [filterOrders, setFilterOrders] = useState();
  const [currentData, setCurrentData] = useState(false);
  const [showModal, setShowModal] = useState();
  const [showModalNeedOrder, setShowModalNeedOrder] = useState();
  const [showModalPlateService, setShowModalPlateService] = useState();
  const [loading, setLoading] = useState(false);
  const [OrderId, setOrderId] = useState();
  const [order, setOrder] = useState();
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [debtNow, setDebtNow] = useState();
  const openSendModal = (e) => {
    e.stopPropagation();
    setIsSendModalOpen(true);
  };
  const fetchOrder = async () => {
    setLoading(true);
    const { data } = await client().get(`${apiUrl}/Order`);
    setLoading(false);
    setFilterOrders();
    const dateRender = _.map(data, (item) => {
      return {
        ...item,
        bankInfoName: _.get(item, 'orderDetail[0].bankInfo.name'),
      };
    });

    setOrders(dateRender);
    const dataRender = _.map(dateRender, (item) => {
      return {
        date: _.get(item, 'saleDate'),
        customeName: _.get(item, 'customerName'),
        customePhone: _.get(item, 'customer.phoneNumber1'),
        customeAddress: _.get(item, 'customerDetailedAddress'),
        productName: _.get(item, 'productName'),
        productColor: _.get(item, 'orderDetail[0].product.color'),
        engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
        frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
        productPrice: _.get(item, 'productPrice'),
        prepayment: _.get(item, 'prepayment'),
        debtBank: _.get(item, 'debtBank'),
        debt: _.get(item, 'debt'),
        note: _.get(item, 'note'),
        bankInfoName: _.get(item, 'orderDetail[0].bankInfo.shortName'),

      };
    });
    setExcelData(dataRender);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const handleRowClick = (record, index, event) => {
    if (event.target.tagName !== 'A') {
      setOrderId(record.id);
      setShowModal(true);
    }
  };

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleOrderNeedCare = (e, record) => {
    e.stopPropagation();
    setOrderId(record.id);
    setShowModalNeedOrder(true);
  };
  const sendPlateService = (e, record) => {
    e.stopPropagation();
    setOrder(record);
    setShowModalPlateService(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleRedpoint = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Xác nhận',
      content: !record.redpoint
        ? `ĐÁNH DẤU ${record.customer.lastName} ${record.customer.firstName} ? `
        : `BỎ ĐÁNH DẤU ${record.customer.lastName} ${record.customer.firstName} ? `,
      onOk: async () => {
        await client()
          .put(`${apiUrl}/Order/UpdateRedpoint/${record.id}`)
          .then((data) => {
            if (data.request.status === 200) {
              message.success('Chỉnh sủa thành công');
              fetchOrder();
            }
          });
      },
      okButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
      cancelButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
    });
  };
  const searchByEmployee = (e) => {
    const value = e;
    const dataToFilter = _.map(Orders, (item) => {
      return {
        ...item,
        employeeName: getFullName(
          _.get(item, 'employee.lastName'),
          _.get(item, 'employee.firstName')
        ),
      };
    });
    const filterTable = dataToFilter.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    if (!value) setFilterOrders();
    else {
      setFilterOrders(filterTable);
      const dataRender = _.map(filterTable, (item) => {
        let bankInfoName = _.get(
          item,
          'orderDetail[0].bankInfo.shortName'
        );
        return {
          date: _.get(item, 'saleDate'),
          customeName: _.get(item, 'customerName'),
          employeeName: _.get(item, 'employeeFirstName'),
          customePhone: _.get(item, 'customer.phoneNumber1'),
          customeAddress: _.get(item, 'customerDetailedAddress'),
          productName: _.get(item, 'productName'),
          productColor: _.get(item, 'orderDetail[0].product.color'),
          engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
          frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
          productPrice: _.get(item, 'productPrice'),
          prepayment: _.get(item, 'prepayment'),
          debtBank: _.get(item, 'debtBank'),
          debt: _.get(item, 'debt'),
          note: _.get(item, 'note'),
          bankInfoName: bankInfoName ? bankInfoName : 'Tiền Mặt',
        };
      });
      setExcelData(dataRender);
    }
  };
  const searchByBank = (e) => {
    const value = e;
    const dataToFilter = _.map(Orders, (item) => {
      return {
        ...item,
        bankInfo: _.get(item, 'orderDetail[0].bankInfo.name'),
      };
    });
    const filterTable = dataToFilter.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );

    if (!value) setFilterOrders();
    else {
      setFilterOrders(filterTable);
      const dataRender = _.map(filterTable, (item) => {
        let bankInfoName = _.get(
          item,
          'orderDetail[0].bankInfo.shortName'
        );
        return {
          date: _.get(item, 'saleDate'),
          customeName: _.get(item, 'customerName'),
          employeeName: _.get(item, 'employeeFirstName'),
          customePhone: _.get(item, 'customer.phoneNumber1'),
          customeAddress: _.get(item, 'customerDetailedAddress'),
          productName: _.get(item, 'productName'),
          productColor: _.get(item, 'orderDetail[0].product.color'),
          engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
          frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
          productPrice: _.get(item, 'productPrice'),
          prepayment: _.get(item, 'prepayment'),
          debtBank: _.get(item, 'debtBank'),
          debt: _.get(item, 'debt'),
          note: _.get(item, 'note'),
          bankInfoName: bankInfoName ? bankInfoName : 'Tiền Mặt',
        };
      });


      setExcelData(dataRender);
    }
  };
  const search = (e) => {
    const value = e.target.value;
    const dataToFilter = _.map(Orders, (item) => {
      return {
        ...item,
        customerName: getFullName(
          _.get(item, 'customer.lastName'),
          _.get(item, 'customer.firstName')
        ),
        customerPhone1: item.customer.phoneNumber1,
        customerPhone2: item.customer.phoneNumber2,
        employeeName: getFullName(
          _.get(item, 'employee.lastName'),
          _.get(item, 'employee.firstName')
        ),
        detailedAddress: item.customer.detailedAddress,
        productName: _.get(_.head(item.orderDetail), 'product.name'),
        engineNumber: _.get(
          _.head(item.orderDetail),
          'product.engineNumber'
        ),
        frameNumber: _.get(
          _.head(item.orderDetail),
          'product.frameNumber'
        ),
        price: _.get(_.head(item.orderDetail), 'product.price'),
      };
    });
    const filterTable = dataToFilter.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    if (!value) setFilterOrders();
    else {
      setFilterOrders(filterTable);
      const dataRender = _.map(filterTable, (item) => {
        let bankInfoName = _.get(
          item,
          'orderDetail[0].bankInfo.shortName'
        );
        return {
          date: _.get(item, 'saleDate'),
          customeName: _.get(item, 'customerName'),
          employeeName: _.get(item, 'employeeFirstName'),
          customePhone: _.get(item, 'customer.phoneNumber1'),
          customeAddress: _.get(item, 'customerDetailedAddress'),
          productName: _.get(item, 'productName'),
          productColor: _.get(item, 'orderDetail[0].product.color'),
          engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
          frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
          productPrice: _.get(item, 'productPrice'),
          prepayment: _.get(item, 'prepayment'),
          debtBank: _.get(item, 'debtBank'),
          debt: _.get(item, 'debt'),
          note: _.get(item, 'note'),
          bankInfoName: bankInfoName ? bankInfoName : 'Tiền Mặt',
        };
      });
      setExcelData(dataRender);
    }
  };
  const handleDelete = (e, record) => {
    e.stopPropagation();
    Modal.confirm({
      title: 'Xác nhận',
      content: 'Bạn có muốn xóa sản phẩm này ?',
      onOk: async () => {
        const { data } = await client().delete(
          `${apiUrl}/Order/DeleteOrderById/?id=${record.id}`
        );
        if (data) message.success('Đã xóa');
        fetchOrder();
      },
      okButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
      cancelButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
    });
  };
  const columns = [
    {
      title: 'Hình thức mua',
      dataIndex: 'orderDetail',
      width: 50,
      key: 'orderDetail',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        a?.orderDetail[0]?.paymentmethod -
        b?.orderDetail[0]?.paymentmethod,
      render: (orderDetail) => {
        const paymentmethod = _.get(
          _.head(orderDetail),
          'paymentmethod'
        );
        const bankInfo = _.get(_.head(orderDetail), 'bankInfo');
        if (paymentmethod === 0) {
          return (
            <Tag color={'green'}>
              {'Ngân Hàng'.toUpperCase()}{' '}
              {bankInfo?.shortName.toUpperCase()}
            </Tag>
          );
        }
        if (paymentmethod === 1) {
          return (
            <Tag color={'volcano'}>{'Tiền Mặt'.toUpperCase()}</Tag>
          );
        }
      },
    },
    {
      title: 'Ngày bán',
      dataIndex: 'saleDate',
      key: 'saleDate',
      sorter: (a, b) => new Date(a.saleDate) - new Date(b.saleDate),
      render: (saleDate) => {
        return <div>{dayjs(saleDate).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'employeeFirstName',
      key: 'employeeFirstName',
      width: 100,
      render: (employeeFirstName) => {
        return (
          <div className="text-xs leading-normal ">
            {employeeFirstName}
          </div>
        );
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      render: (
        customer,
        { customerName, orderDetail, note, redpoint }
      ) => {
        let color = redpoint && 'text-red-500';
        return (
          <div
            className={`leading-normal text-4  font-semibold ${color} `}
          >
            {redpoint ? (
              <Tooltip
                title={
                  note
                    ? note
                    : _.get(_.head(orderDetail), 'note')
                }
              >
                {redpoint && (
                  <i className="mr-1 fas fa-circle"></i>
                )}
                {customerName}
              </Tooltip>
            ) : (
              <>
                <Tooltip
                  title={
                    customer.phoneNumber1
                      ? customer.phoneNumber1
                      : customer.phoneNumber2
                  }
                  placement="top"
                >
                  {customerName}
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Địa chỉ ',
      dataIndex: 'customerDetailedAddress',
      key: 'customerDetailedAddress',
      width: 200,
      render: (customerDetailedAddress) => {
        return (
          <div className="text-xs leading-normal ">
            {customerDetailedAddress}
          </div>
        );
      },
    },
    {
      title: 'Xe ',
      dataIndex: 'orderDetail',
      key: 'productName',
      render: (orderDetail) => {
        return (
          <div className="text-xs leading-normal ">
            {_.get(_.head(orderDetail), 'product.name')}
            {_.get(_.head(orderDetail), 'product.color') &&
              '-' + _.get(_.head(orderDetail), 'product.color')}
          </div>
        );
      },
    },

    {
      title: 'Số Khung',
      dataIndex: 'orderDetail',
      key: 'orderDetail',
      render: (orderDetail) => {
        return (
          <div className="text-xs leading-normal ">
            {_.get(_.head(orderDetail), 'product.frameNumber')}
          </div>
        );
      },
    },
    {
      title: 'Giá ',
      dataIndex: 'orderDetail',
      key: 'orderDetail',
      render: (orderDetail) => {
        const priceOrder = _.get(_.head(orderDetail), 'priceOrder');
        return (
          <div className="text-xs leading-normal ">
            {priceOrder && priceOrder !== 0
              ? `${priceOrder}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              ) + ' VNĐ'
              : 0}
          </div>
        );
      },
    },
    {
      title: 'Nợ Cửa Hàng ',
      dataIndex: 'orderDetail',
      key: 'orderDetail',
      render: (orderDetail) => {
        const debt = _.get(_.head(orderDetail), 'debt');
        return (
          <div className="text-xs leading-normal ">
            {debt && debt !== 0
              ? `${debt}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
              ' VNĐ'
              : 0}
          </div>
        );
      },
    },
    {
      title: 'Ngày Giao Biển',
      dataIndex: 'orderDetail',
      key: 'orderDetail',
      render: (orderDetail) => {
        const npDeliveryDate = _.get(
          _.head(orderDetail),
          'npDeliveryDate'
        );
        return (
          <div>
            {npDeliveryDate
              ? dayjs(npDeliveryDate).format('DD/MM/YYYY')
              : ''}
          </div>
        );
      },
    },
    {
      title: 'Ghi chú ',
      dataIndex: 'note',
      className: ' ',
      render: (note) => {
        return <div className="mx-auto truncate max-w-52 ">{note}</div>;
      },
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: ({ redpoint, isOrderNeedCare, orderDetail }, record) => {
        const isSentService = _.get(
          _.head(orderDetail),
          'isSentService'
        );
        const debt = _.get(_.head(orderDetail), 'debt');
        return (
          <div className="flex">
            <Tooltip
              title={
                redpoint
                  ? 'Bỏ đánh dấu'
                  : 'Đánh dấu khách hàng '
              }
              placement="right"
            >
              <ButtonCustom
                onClick={(e) => handleRedpoint(e, record)}
                textColor={
                  redpoint
                    ? 'text-rose-600 '
                    : 'text-black-600 '
                }
                icon={<i className="fa fa-map-pin"></i>}
              />
            </Tooltip>
            <Tooltip title={`Đơn hàng cần xử lý`} placement="right">
              <ButtonCustom
                onClick={(e) => handleOrderNeedCare(e, record)}
                textColor={
                  isOrderNeedCare
                    ? 'text-rose-600 '
                    : 'text-black-600 '
                }
                icon={<i className="fas fa-hard-hat"></i>}
              />
            </Tooltip>

            {!isSentService && (
              <Tooltip
                title={`Gửi dịch vụ biển số `}
                placement="right"
              >
                <ButtonCustom
                  onClick={(e) => sendPlateService(e, record)}
                  textColor="text-green-600"
                  icon={
                    <i className="fas fa-paper-plane"></i>
                  }
                />
              </Tooltip>
            )}
            <Tooltip title={`In đơn hàng`} placement="right">
              <ButtonCustom
                onClick={(e) =>
                  window.open(
                    `/report/${record.id}`,
                    '_blank'
                  )
                }
                textColor={'text-rose-600'}
                icon={<i class="fas fa-print"></i>}
              />
            </Tooltip>
            {
              <Tooltip
                title={'Giao biển số hoặc cà vet'}
                placement="right"
              >
                <ButtonCustom
                  onClick={(e) => {
                    openSendModal(e);
                    setDebtNow(debt);
                    setOrder(record);
                  }}
                  textColor="text-yellow-600"
                  icon={
                    <i className="fas fa-file-invoice-dollar"></i>
                  }
                />
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      title: '',
      key: 'delete',
      render: (record) => {
        return (
          <Tooltip title={`Xoá đơn hàng`} placement="right">
            <ButtonCustom
              onClick={(e) => handleDelete(e, record)}
              textColor={'text-red-400'}
              icon={<i className="fas fa-trash-alt"></i>}
            />
          </Tooltip>
        );
      },
    },
  ];

  const handleDateChange = (values) => {
    if (!values) return setFilterOrders(Orders);
    const [start, end] = values;
    const s = dayjs(start).format('YYYY-MM-DD');
    const e = dayjs(end).format('YYYY-MM-DD');
    const filterDates = _.filter(Orders, (item) => {
      return dayjs(item.saleDate).isBetween(s, e, '', '[]');
    });
    setFilterOrders(filterDates);
    const dataRender = _.map(filterDates, (item) => {
      let bankInfoName = _.get(item, 'orderDetail[0].bankInfo.shortName');
      return {
        date: _.get(item, 'saleDate'),
        customeName: _.get(item, 'customerName'),
        employeeName: _.get(item, 'employeeFirstName'),
        customePhone: _.get(item, 'customer.phoneNumber1'),
        customeAddress: _.get(item, 'customerDetailedAddress'),
        productName: _.get(item, 'productName'),
        productColor: _.get(item, 'orderDetail[0].product.color'),
        engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
        frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
        productPrice: _.get(item, 'productPrice'),
        prepayment: _.get(item, 'prepayment'),
        debtBank: _.get(item, 'debtBank'),
        debt: _.get(item, 'debt'),
        note: _.get(item, 'note'),
        bankInfoName: bankInfoName ? bankInfoName : 'Tiền Mặt',
      };
    });
    setExcelData(dataRender);
  };
  const onChange = (e) => {
    if (!e.target.checked) return setFilterOrders(Orders);
    const filterDates = _.filter(Orders, (item) => {
      return item.redpoint === true;
    });

    setFilterOrders(filterDates);
    const dataRender = _.map(filterDates, (item) => {
      let bankInfoName = _.get(item, 'orderDetail[0].bankInfo.shortName');
      return {
        date: _.get(item, 'saleDate'),
        customeName: _.get(item, 'customerName'),
        employeeName: _.get(item, 'employeeFirstName'),
        customePhone: _.get(item, 'customer.phoneNumber1'),
        customeAddress: _.get(item, 'customerDetailedAddress'),
        productName: _.get(item, 'productName'),
        productColor: _.get(item, 'orderDetail[0].product.color'),
        engineNumber: _.get(item, 'orderDetail[0].product.engineNumber'),
        frameNumber: _.get(item, 'orderDetail[0].product.frameNumber'),
        productPrice: _.get(item, 'productPrice'),
        prepayment: _.get(item, 'prepayment'),
        debtBank: _.get(item, 'debtBank'),
        debt: _.get(item, 'debt'),
        note: _.get(item, 'note'),
        bankInfoName: bankInfoName ? bankInfoName : 'Tiền Mặt',
      };
    });
    setExcelData(dataRender);
  };
  return (
    <div>
      <div className="font-bold text-blue-500">Đơn hàng</div>
      <div className="my-4">
        <div className="flex flex-wrap justify-between mb-4">
          <div>
            <Input
              className="w-64 mt-3 mr-3"
              placeholder="TÌM ĐƠN HÀNG, SỐ ĐT, SỐ KHUNG"
              onChange={search}
              allowClear
            />
            <RangePicker
              onChange={handleDateChange}
            // disabledDate={disabledDate}
            />
            <SelectEmployee
              className="pl-2"
              onChange={(e) => searchByEmployee(e)}
            ></SelectEmployee>
            <div className='flex justify-start gap-3 py-4'>
              <SelectBank
                label=""
                className="pl-2"
                required={false}
                onChange={(e) => searchByBank(e)}
              />
              <Checkbox onChange={onChange}>Đơn hàng đánh dấu</Checkbox>
            </div>
            <div className="col-md-4 center">
              <ExportToExcel
                data={execlData}
                fileName={'this.state.fileName'}
                headers={headers}
              />
            </div>
          </div>

          <div className="mt-3">
            <ButtonReload
              onClick={() => fetchOrder()}
              className="mr-4"
              text="Refresh"
            />
            <ButtonAdd onClick={handleAdd} className="mr-4" />
            <ExcelUploader
              url="api/ImportExcel/ReadOrderFile"
              title="đơn hàng "
              reloadData={() => fetchOrder()}
            />
          </div>
        </div>

        <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
          <Table
            scroll={{ x: 'max-content' }}
            size="small"
            loading={loading}
            columns={columns}
            dataSource={!filterOrders ? Orders : filterOrders}
            rowClassName="cursor-pointer text-xs"
            onRow={(record, index) => ({
              onClick: (event) =>
                handleRowClick(record, index, event),
            })}
          />
        </div>
      </div>
      {showModalNeedOrder && (
        <ModalOrderNeedCare
          OrderId={OrderId}
          setShowModal={setShowModalNeedOrder}
          showModal={showModalNeedOrder}
          currentData={currentData}
          setCurrentData={setCurrentData}
          fetchOrder={fetchOrder}
          filterOrders={filterOrders}
          setOrderId={setOrderId}
        />
      )}
      {showModalPlateService && (
        <ModalLicensePlateService
          Order={order}
          setShowModal={setShowModalPlateService}
          showModal={showModalPlateService}
          currentData={currentData}
          setCurrentData={setCurrentData}
          fetchOrder={fetchOrder}
          filterOrders={filterOrders}
          setOrderId={setOrderId}
        />
      )}
      {showModal && (
        <OrderDetail
          OrderId={OrderId}
          setShowModal={setShowModal}
          showModal={showModal}
          currentData={currentData}
          setCurrentData={setCurrentData}
          fetchOrder={fetchOrder}
          filterOrders={filterOrders}
          setOrderId={setOrderId}
        />
      )}
      <ModalSentNumberBike
        showModal={isSendModalOpen}
        setShowModal={setIsSendModalOpen}
        debt={debtNow}
        data={order}
      />
    </div>
  );
};

export default withRouter(Order);
