import { Card, Spin, Typography, DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import Chart from 'react-apexcharts';
import { MinusOutlined } from '@ant-design/icons';

const { MonthPicker } = DatePicker;

const { Title } = Typography;
export function ChartBankRevenue({ handleRangeChange, configChartOptions }) {
    return (
        <Spin spinning={configChartOptions.loading}>
            <Card>
                <div className="linechart">
                    <div>
                        <Title level={5}>Doanh số và doanh thu Ngân hàng</Title>
                        <div className="banks">
                            <ul>
                                <li>{<MinusOutlined />} Doanh Thu</li>
                                <li>{<MinusOutlined />} Doanh số</li>
                            </ul>
                        </div>
                    </div>
                    <MonthPicker
                        defaultValue={dayjs()}
                        onChange={handleRangeChange}
                    />
                </div>
                <Chart
                    className="full-width"
                    options={configChartOptions.options}
                    series={configChartOptions.series}
                    type="line"
                    height={300}
                    width={'100%'}
                />
            </Card>
        </Spin>
    );
}
