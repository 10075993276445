import { Button } from 'antd';

const ButtonCancel = ({ text = 'Đóng', ...props }) => {
    return (
        <button
            {...props}
            className=" mr-3 inline-block px-2 py-2 font-bold text-center bg-slate-500 from-green-600 to-lime-400 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white"
        >
            {text}
        </button>
    );
};
export default ButtonCancel;
