import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import client from '../helpers/client';
import apiUrl from '../settings';
import { Table, Tooltip } from 'antd';
import ButtonCustom from './elements/ButtonCustom';
import _ from 'lodash';
import dayjs from 'dayjs';

function InvoiceList(props) {
    const [invoiceList, setInvoiceList] = useState([]);
    const [amountToday, setAmountToday] = useState([]);
    const fetchData = async () => {
        const { data } = await client().get(`${apiUrl}/ProcessingPayment`);
        setInvoiceList(data);
    };
    const getAmountToday = async () => {
        const { data } = await client().get(
            `${apiUrl}/ProcessingPayment/Amount`
        );
        setAmountToday(data);
    };
    const columns = [
        {
            dataIndex: 'deliveryDate',
            key: 'deliveryDate',
            title: () => {
                return <div className="editable-row">Ngày giao cavet</div>;
            },
            render: (deliveryDate) =>
                deliveryDate ? dayjs(deliveryDate).format('DD/MM/YYYY') : '',
        },
        {
            title: 'Tên khách hàng',
            dataIndex: 'customerName',
            key: 'customerName',

            render: (customerName) => <span>{customerName}</span>,
        },
        {
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            title: () => {
                return <div className="editable-row">Số tiền thanh toán </div>;
            },
            render: (paymentAmount) => {
                return (
                    <div className="leading-normal text-sm font-semibold">
                        {paymentAmount !== 0
                            ? `${paymentAmount}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                              ) + ' VNĐ'
                            : 0}
                    </div>
                );
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            render: (note) => (
                <div className="truncate max-w-52 mx-auto ">{note}</div>
            ),
        },
        {
            title: 'Action',
            width: 50,
            key: 'delete',
            render: (record) => {
                return (
                    <Tooltip title={`In hoá đơn`} placement="right">
                        <ButtonCustom
                            onClick={(e) =>
                                window.open(`/invoice/${record.id}`, '_blank')
                            }
                            textColor={'text-green-400'}
                            icon={<i class="fas fa-print"></i>}
                        />
                    </Tooltip>
                );
            },
        },
    ];
    useEffect(() => {
        fetchData();
        getAmountToday();
    }, []);
    return (
        <div>
            {!_.isEmpty(invoiceList) && (
                <div className="relative flex flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border">
                    <div className="border-black/12.5 mb-0 rounded-t-2xl border-b-0 border-solid bg-white p-6 pb-0">
                        <div className="flex-none w-7/12 max-w-full px-3 mt-0 lg:w-1/2 lg:flex-none">
                            <h6>Danh sách hoá đơn thanh toán</h6>
                            <div className="text-4 font-bold">
                                Số tiền thu hôm nay :{' '}
                                {amountToday !== 0
                                    ? `${amountToday}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ','
                                      ) + ' VNĐ'
                                    : 0}
                            </div>
                        </div>
                    </div>
                    <Table
                        className="text-green-500"
                        columns={columns}
                        dataSource={invoiceList}
                        onRow={(record, index) => ({})}
                    />
                </div>
            )}
        </div>
    );
}

InvoiceList.propTypes = {};

export default InvoiceList;
