/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import _ from 'lodash';

import apiUrl from '../settings/index';
import notify from '../components/notification';
import {
    ApplicationPaths,
    QueryParameterNames,
} from '../components/api-authorization/ApiAuthorizationConstants';

const notification = _.throttle(notify, 1000);

export default function (noNotify, opts = {}) {
    const defaultOptions = {};
    function getAccessToken() {
        const token = JSON.parse(localStorage.getItem('token'));
        return token && token.access_token;
    }
    let token = getAccessToken();
    if (!opts.noToken) {
        if (!token) token = getAccessToken();
        defaultOptions.headers = {
            Authorization: 'Bearer ' + token,
        };
    }
    return {
        get: (url, options) =>
            axios
                .get(validateUrl(url), _.merge(defaultOptions, options))
                .then((response) => handleResponse(response, noNotify, options))
                .catch((err) => handleError(err, noNotify)),
        post: (url, data, options) =>
            axios
                .post(validateUrl(url), data, _.merge(defaultOptions, options))
                .then((response) => handleResponse(response, noNotify, options))
                .catch((err) => handleError(err, noNotify)),
        put: (url, data, options) =>
            axios
                .put(validateUrl(url), data, _.merge(defaultOptions, options))
                .then((response) => handleResponse(response, noNotify, options))
                .catch((err) => handleError(err, noNotify)),
        delete: (url, data, options) =>
            axios
                .delete(
                    validateUrl(url),
                    _.merge(defaultOptions, options, { data })
                )
                .then((response) => handleResponse(response, noNotify, options))
                .catch((err) => handleError(err, noNotify)),
    };
}

function validateUrl(url) {
    if (!url || !url.match(/^http(s*):\/\//)) {
        url = `${apiUrl}${url}`;
    }
    return url;
}

function handleResponse(response, noNotify, options) {
    return response;
}

function handleError(error, noNotify) {
    if (_.get(error, 'response.status') === 401) {
        logout();
    }
    if (!noNotify) {
        notification(
            'error',
            'Error!',
            _.get(error, 'response.data.title') ||
                _.get(error, 'response.data') ||
                'Request Fail...'
        );
    }
    return error;
}

function logout() {
    var link = document.createElement('a');
    link.href = window.location.pathname;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
        QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;
    window.location.href = redirectUrl;
}
