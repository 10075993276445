import { Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useState } from 'react';
import { createBankInfo, updateBankInfo } from '../../redux/actions/bankInfo';
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';

const BankInfoDetail = ({
    setShowModal,
    showModal,
    currentData = {},
    createBankInfo,
    updateBankInfo,
    fetchBankInfo,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const isView = currentData.id;

    const handleOk = () => {
        form.validateFields().then(async (values) => {
            setLoading(true);
            const data = !isView
                ? await createBankInfo(values)
                : await updateBankInfo({ ...values, id: currentData.id });
            if (data) {
                message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                !isView && setShowModal();
                fetchBankInfo();
            }
            setLoading(false);
        });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal
                title={
                    isView
                        ? 'Chỉnh sửa thông tin ngân hàng'
                        : 'Thêm mới ngân hàng'
                }
                open={showModal}
                centered
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={currentData}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            <Row gutter={16}>
                                <Col md={12} className="mb-1">
                                    <Form.Item
                                        name="name"
                                        label="Tên ngân hàng"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col md={12} className="mb-1">
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng điền !',
                                            },
                                        ]}
                                        name="shortName"
                                        label="Viết tắt"
                                    >
                                        <Input className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default connect(null, {
    createBankInfo,
    updateBankInfo,
})(BankInfoDetail);
