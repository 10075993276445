import * as types from '../../settings/action';

const initialState = {
    data: [],
    isFetching: false,
    err: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case types.PARTNER_FETCH_START:
            return { ...state, isFetching: true, data: [] };

        case types.PARTNER_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: [...state.data, ...action.data],
            };

        case types.PARTNER_FETCH_FAILURE:
            return { ...state, isFetching: false, err: action.errors };

        case types.PARTNER_DELETE_FINISH:
            let newData = state.data.filter((da) => da._id !== action.id);
            return { ...state, isFetching: false, data: newData };

        default:
            return state;
    }
}
