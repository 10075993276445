import { Col, Form, message, Modal, Row, Select, Spin ,DatePicker} from 'antd';
import React, { useState } from 'react';
import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import client from '../../helpers/client';
import apiUrl from '../../settings';
import ButtonCancel from '../../components/elements/ButtonCancel';
import ButtonAdd from '../../components/elements/ButtonAdd';
import SelectPartner from '../../components/SelectPartner';
import { CARD_TYPES } from '../../settings/constant';
import dayjs from 'dayjs';

function ModalLicensePlateService({
    Order,
    setShowModal,
    showModal,
    currentData = {},
}) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    };

    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
                orderDetailId: Order?.orderDetail[0]?.id,
                customerId: Order?.customerId,
            };

            setLoading(true);
            await client()
                .post(
                    `${apiUrl}/LicensePlateService/AddLicensePlateService`,
                    transformData
                )
                .then((data) => {
                    if (data.request.status === 200) {
                        message.success('Thêm thành công !');
                    }
                });
            handleCancel();
            setLoading(false);
        });
    };
    return (
        <Modal
            title="Gửi dịch vụ biên số "
            open={showModal}
            centered
            width={800}
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd
                            icon={<PlusOutlined style={{ fontSize: '15px' }} />}
                            onClick={handleOk}
                            text="Tạo"
                        />
                    </div>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <div className="mt-6">
                        <Row gutter={16}>

                            <Col md={24} className="mb-1">
                                <SelectPartner />
                            </Col>
                            <Col md={12} className="mb-1">
                                <Form.Item
                                    name="cardType"
                                    label="Loại giấy tờ đính kèm"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại giấy tờ"
                                        optionFilterProp="label"
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        options={_.map(CARD_TYPES, (item) => ({
                                            value: item.id,
                                            label: item.name,
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12}  className="mb-1">
                                    <Form.Item
                                        label="Ngày gửi"
                                        initialValue={dayjs()}
                                        name="sentDate"
                                    >
                                        <DatePicker
                                            className="w-full"
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                            <Col md={24} className="mb-1">
                                <Form.Item name="note" label="Ghi chú">
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
}

ModalLicensePlateService.propTypes = {};

export default ModalLicensePlateService;
