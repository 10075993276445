import { Form, Select } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEmployees } from '../redux/actions/employee';

const SelectEmployee = ({ getEmployees, employees, ...props }) => {
    useEffect(() => {
        getEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form.Item
            name="employeeId"
            label="Nhân viên bán hàng "
            rules={[
                {
                    required: true,
                    message: 'Vui lòng chọn!',
                },
            ]}
        >
            <Select
                allowClear
                placeholder="Chọn nhân viên"
                optionFilterProp="label"
                showSearch
                dropdownMatchSelectWidth={false}
                loading={employees.isFetching}
                options={_.map(employees.data, (item) => ({
                    value: item.id,
                    label: item.lastName + ' ' + item.firstName,
                }))}
                {...props}
            />
        </Form.Item>
    );
};

const mapStateToProps = (state) => {
    return {
        employees: state.Employee,
    };
};
export default connect(mapStateToProps, {
    getEmployees,
})(SelectEmployee);
