import { Card, Spin, Typography, DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import Chart from 'react-apexcharts';

const { RangePicker } = DatePicker;

const { Title } = Typography;
export function ChartEmployeeRevenue({
    handleRangeChange,
    configChartOptions,
}) {
    return (
        <Spin spinning={configChartOptions.loading}>
            <Card>
                <div>
                    <div className="flex justify-between">
                        <Title level={5}>Doanh số nhân viên</Title>
                        <RangePicker
                            format={'DD-MM-YYYY'}
                            onChange={handleRangeChange}
                            defaultValue={[
                                dayjs().startOf('month'),
                                dayjs().endOf('month'),
                            ]}
                        />
                    </div>
                    <Chart
                        className="bar-chart mt-3"
                        options={configChartOptions.options}
                        series={configChartOptions.series}
                        type="bar"
                        height={325}
                    />
                </div>
            </Card>
        </Spin>
    );
}
