import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectRoles = ({ ...props }) => {
    const { data, loading } = useData(`/Role/GetAllRoles`);

    return (
        <Form.Item name="roleId" label="Vai trò">
            <Select
                aria-autocomplete="none"
                placeholder="Chọn vai trò cho nhân viên"
                allowClear
                showSearch
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                optionFilterProp="label"
                {...props}
            />
        </Form.Item>
    );
};

export default SelectRoles;
