import React, { useState } from 'react';
import { Upload, message, Modal } from 'antd';
import axios from 'axios';
import readXlsxFile from 'read-excel-file';

const ExcelUploader = ({ url, text = 'Import Excel', title, reloadData }) => {
    const [fileList, setFileList] = useState([]);

    function getAccessToken() {
        const token = JSON.parse(localStorage.getItem('token'));
        return token && token.access_token;
    }
    let token = getAccessToken();

    const handleChange = (info) => {
        let fileList = [...info.fileList];
        // Limit the number of files
        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            return file;
        });
        setFileList(fileList);
    };

    const handleSubmit = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token,
                },
            });
            setFileList([]);
            message.success(
                `Đã thêm thành công ${response.data.createdNum} records`
            );
            reloadData && reloadData();
        } catch (error) {
            message.error('Thêm dữ liệu không thành công ');
        }
    };
    const uploadFile = (file) => {
        readXlsxFile(file).then((rows) => {
            Modal.confirm({
                title: 'Xác nhận',
                content: `Thêm mới ${rows.length} ${title} ?`,
                onOk: async () => {
                    handleSubmit(file);
                },
                okButtonProps: {
                    className:
                        'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
                },
                cancelButtonProps: {
                    className:
                        'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
                },
            });
        });
    };
    return (
        <Upload
            fileList={fileList}
            onChange={handleChange}
            beforeUpload={(file) => uploadFile(file)}
            onRemove={() => setFileList([])}
        >
            <button
                className={`inline-block px-2 py-2 mr-3 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-purple-700 to-pink-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs`}
            >
                <i className="fas fa-file-import mr-1"></i>
                {text}
            </button>
        </Upload>
    );
};

export default ExcelUploader;
