import client from '../../helpers/client';
import apiUrl from '../../settings';

export function updateLicensePS(data) {
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/LicensePlateService/UpdateLicensePlateServiceById/${data.id}`,
            data
        );
        return result;
    };
}
