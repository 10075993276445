import { Col, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import client from '../helpers/client';
import ListNeedCareOrder from '../pages/NeedCareOrder/ListNeedCareOrder';
import apiUrl from '../settings';
import { formatterChart } from '../helpers/utility';
import _ from 'lodash';
import dayjs from 'dayjs';
import { ChartEmployeeRevenue } from './chart/ChartEmployeeRevenue';
import { OrderRevenueChart } from './chart/OrderRevenueChart';
import { ChartBankRevenue } from './chart/ChartBankRevenue';
import { useSelector } from 'react-redux';
import { GIAM_DOC } from '../helpers/roles';
import InvoiceList from './InvoiceList';

const defaultChartValue = {
    options: {
        chart: {
            id: '',
        },
        xaxis: {
            categories: [],
        },
    },
    series: [
        {
            name: 'series-1',
            data: [],
        },
    ],
    loading: false,
};
const Home = () => {
    const { roles } = useSelector((state) => state.auth);
    const [year, setYear] = useState(dayjs().get('year'));
    const [orderRevenueChart, setOrderRevenueChart] =
        useState(defaultChartValue);

    const [employeeRevenueChart, setEmployeeRevenueChart] =
        useState(defaultChartValue);
    const [bankRevenueChart, setBanckRevenueChart] =
        useState(defaultChartValue);
    const [ranges, setRanges] = useState({
        fromTime: dayjs().startOf('month').toISOString(),
        toTime: dayjs().endOf('month').toISOString(),
    });
    const [months, setMonths] = useState({
        month: dayjs().month() + 1,
        year: dayjs().year(),
    });
    function handleMonthChange(dateString) {
        console.log(
            '🚀 ~ file: Home.js:55 ~ handleMonthChange ~ value:',
            dayjs(dateString)
        );
        let value = {
            month: dayjs(dateString).month() + 1,
            year: dayjs(dateString).year(),
        };

        setMonths(value);
    }
    // setMonths(dayjs(date).year());

    const fetchTotalOrderRevenueByMonths = useCallback(async () => {
        setOrderRevenueChart({ ...orderRevenueChart, loading: true });
        const { data } =
            year &&
            (await client().get(
                `${apiUrl}/ChartData/TotalOrderRevenueByMonth?year=${year}`
            ));
        if (data) {
            const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            _.forEach(months, (item, i) => {
                const element = data.data1[i];
                if (!element) return (data.data1[i++] = 0);
            });
            const charData = {
                options: {
                    colors: ['#cb0c9f', '#443e75'],

                    chart: {
                        width: '100%',
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },

                    grid: {
                        show: true,
                        borderColor: '#ccc',
                        strokeDashArray: 2,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        width: 3,
                        dashArray: 0,
                    },
                    xaxis: {
                        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontWeight: 600,
                            },
                            formatter: function (value) {
                                return value;
                            },
                        },
                    },
                    yaxis: [
                        {
                            labels: {
                                formatter: function (value) {
                                    return formatterChart(value);
                                },
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    colors: ['#8c8c8c'],
                                },
                            },
                        },
                        {
                            opposite: true,
                            labels: {
                                formatter: function (value) {
                                    return formatterChart(value);
                                },
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    colors: ['#8c8c8c'],
                                },
                            },
                        },
                    ],
                },
                series: [
                    {
                        name: 'Doanh thu',
                        data: data.data1,
                        offsetY: 0,
                        type: 'line',
                    },
                    {
                        name: 'Tiền khách hàng nợ',
                        data: data.data2,
                        offsetY: 0,
                        type: 'line',
                    },
                ],
            };

            setOrderRevenueChart({ ...charData, loading: false });
        } else {
            setOrderRevenueChart({
                ...defaultChartValue,
                loading: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    const fetchTotalEmployeeRevenueInTimeRange = useCallback(async () => {
        setEmployeeRevenueChart({ ...employeeRevenueChart, loading: true });

        const { data } = await client().get(
            `${apiUrl}/ChartData/TotalEmployeeOrdersInTimeRange`,
            {
                params: {
                    ...ranges,
                },
            }
        );
        if (data) {
            _.forEach(data.categories, (item, i) => {
                const element = data.data1[i];
                if (!element) return (data.data1[i++] = '');
            });
            const charData = {
                options: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            colors: ['#8c8c8c'],
                        },
                        offsetY: -30,
                        formatter: function (value) {
                            return formatterChart(value);
                        },
                    },
                    stroke: {
                        show: true,
                        width: 1,
                        colors: ['transparent'],
                    },
                    grid: {
                        show: true,
                        borderColor: '#ccc',
                        strokeDashArray: 2,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    chart: {
                        type: 'bar',
                        width: '100%',
                        height: 'auto',

                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '25%',
                            borderRadius: 5,
                        },
                    },
                    xaxis: {
                        categories: data.categories,
                        labels: {
                            show: true,
                            align: 'right',
                            minWidth: 0,
                            maxWidth: 160,
                            style: {
                                colors: [
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                ],
                            },
                        },
                    },
                    yaxis: [
                        {
                            labels: {
                                formatter: function (value) {
                                    return formatterChart(value);
                                },
                                show: true,
                                align: 'right',
                                minWidth: 0,
                                maxWidth: 160,
                                style: {
                                    colors: [
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                        '#fff',
                                    ],
                                },
                            },
                        },
                    ],
                },
                series: [
                    {
                        name: 'Doanh số',
                        data: data.data1,
                        color: '#fff',
                    },
                ],
            };
            setEmployeeRevenueChart({
                ...charData,
                loading: false,
            });
        } else {
            setEmployeeRevenueChart({
                ...defaultChartValue,
                loading: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges]);
    const fetchBankRevenueInMonth = useCallback(async () => {
        setBanckRevenueChart({ ...bankRevenueChart, loading: true });
        const { data } =
            months?.month && months?.year
                ? await client().get(
                      `${apiUrl}/ChartData/TotalBankCountAndRevenueInMonth?month=${months.month}&year=${months.year}`
                  )
                : setBanckRevenueChart({ ...bankRevenueChart, loading: false });
        if (data) {
            _.forEach(data.categories, (item, i) => {
                const element = data.data1[i];
                if (!element) return (data.data1[i++] = '');
            });
            const charData = {
                options: {
                    chart: {
                        width: '100%',
                        height: 350,
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                    },

                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '25%',
                            borderRadius: 5,
                        },
                    },
                    grid: {
                        show: true,
                        borderColor: '#ccc',
                        strokeDashArray: 2,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    stroke: {
                        show: true,
                        curve: 'smooth',
                        lineCap: 'butt',
                        width: 3,
                        dashArray: 0,
                    },
                    xaxis: {
                        categories: data.categories,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontWeight: 600,
                            },
                            formatter: function (value) {
                                return value;
                            },
                        },
                    },
                    yaxis: [
                        {
                            labels: {
                                formatter: function (value) {
                                    return formatterChart(value);
                                },
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    colors: ['#8c8c8c'],
                                },
                            },
                        },
                        {
                            opposite: true,
                            labels: {
                                formatter: function (value) {
                                    return formatterChart(value);
                                },
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    colors: ['#8c8c8c'],
                                },
                            },
                        },
                    ],
                },
                series: [
                    {
                        name: 'Doanh Thu',
                        data: data.data2,
                        offsetY: 0,
                        type: 'line',
                    },
                    {
                        name: 'Doanh số',
                        data: data.data1,
                        offsetY: 0,
                        type: 'bar',
                    },
                ],
            };
            setBanckRevenueChart({ ...charData, loading: false });
        } else {
            setBanckRevenueChart({
                ...defaultChartValue,
                loading: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [months]);
    useEffect(() => {
        fetchTotalOrderRevenueByMonths();
    }, [fetchTotalOrderRevenueByMonths]);
    useEffect(() => {
        fetchBankRevenueInMonth();
    }, [fetchBankRevenueInMonth]);
    useEffect(() => {
        fetchTotalEmployeeRevenueInTimeRange();
    }, [fetchTotalEmployeeRevenueInTimeRange]);

    const onYearChange = (value) => {
        setYear(dayjs(value).year());
    };

    const handleRangeChange = (values) => {
        const [start, end] = values;
        setRanges({
            fromTime: dayjs(start).toISOString(),
            toTime: dayjs(end).toISOString(),
        });
    };

    return (
        <>
            <div className="-mx-3">
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <ListNeedCareOrder />
                    </Col>
                    <Col xs={24} md={12}>
                        <InvoiceList />
                    </Col>
                </Row>
            </div>
            <div className=" mt-6 -mx-3">
                <Row gutter={[12, 12]}>
                    {roles[0] === GIAM_DOC && (
                        <Col xs={24} sm={24} md={12}>
                            <OrderRevenueChart
                                onYearChange={onYearChange}
                                configChartOptions={orderRevenueChart}
                            />
                        </Col>
                    )}
                    <Col xs={24} sm={24} md={12}>
                        <ChartEmployeeRevenue
                            handleRangeChange={handleRangeChange}
                            configChartOptions={employeeRevenueChart}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <ChartBankRevenue
                            handleRangeChange={handleMonthChange}
                            configChartOptions={bankRevenueChart}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Home;
