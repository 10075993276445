import { Input, message, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import { DeleteOutlined } from '@ant-design/icons';
import ManufacturerDetail from './manufacturerDetail';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { ACTION } from '../../settings/constant';
import ButtonReload from '../../components/elements/ButtonReload';
const Manufacturer = ({ router }) => {
    //   const { params } = router;
    //   const { id } = params;
    const [Manufacturers, setManufacturers] = useState();
    const [filterManufacturers, setFilterManufacturers] = useState();
    const [currentData, setCurrentData] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchManufacturer = async () => {
        setLoading(true);
        const { data } = await client().get(`${apiUrl}/Manufacturer`);
        setLoading(false);
        setManufacturers(data);
    };

    useEffect(() => {
        fetchManufacturer();
    }, []);

    const handleRowClick = (record, index, event) => {
        if (event.target.tagName !== 'A') {
            setCurrentData({
                ...record,
                // dateOfBirth: dayjs(record.dateOfBirth),
            });
        }
    };

    const handleAdd = () => {
        setCurrentData(ACTION.CREATE);
    };

    const handleDelete = (e, record) => {
        e.stopPropagation();
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có muốn xóa ngân hàng này ?',
            onOk: async () => {
                const { data } = await client().delete(
                    `${apiUrl}/Manufacturer/id?id=${record.id}`
                );
                if (data) message.success('Đã xóa');
                if (filterManufacturers)
                    setFilterManufacturers(
                        filterManufacturers.filter(
                            (item) => item.id !== record.id
                        )
                    );
                fetchManufacturer();
            },
            okButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
            cancelButtonProps: {
                className:
                    'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
            },
        });
    };

    const search = (e) => {
        const value = e.target.value;
        const filterTable = Manufacturers.filter((o) =>
            Object.keys(o).some((k) =>
                String(o[k]).toLowerCase().includes(value.toLowerCase())
            )
        );
        if (!value) setFilterManufacturers();
        else {
            setFilterManufacturers(filterTable);
        }
    };

    const columns = [
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        handleDelete(event, record);
                    },
                };
            },
            render: () => (
                <span>
                    <DeleteOutlined className="text-rose-600 text-lg" />
                </span>
            ),
        },
    ];

    return (
        <div>
            <div className="text-blue-500 font-bold">Hãng</div>
            <div className="my-4">
                <div className="flex justify-between mb-4">
                    <Input
                        className="w-64"
                        placeholder="Lọc ..."
                        onChange={search}
                    />
                    <div>
                        <ButtonReload
                            onClick={() => fetchManufacturer()}
                            className="mr-4"
                            text="Refresh"
                        />
                        <ButtonAdd onClick={handleAdd} />
                    </div>
                </div>

                <div className="relative flex flex-col w-full min-w-0 mb-0 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                    <Table
                        loading={loading}
                        className="text-green-500"
                        columns={columns}
                        dataSource={
                            !filterManufacturers
                                ? Manufacturers
                                : filterManufacturers
                        }
                        rowClassName="cursor-pointer"
                        onRow={(record, index) => ({
                            onClick: (event) =>
                                handleRowClick(record, index, event),
                        })}
                    />
                </div>
            </div>

            {(currentData || currentData === ACTION.CREATE) && (
                <ManufacturerDetail
                    setShowModal={setCurrentData}
                    showModal={currentData}
                    currentData={currentData}
                    fetchManufacturer={fetchManufacturer}
                    filterManufacturers={filterManufacturers}
                />
            )}
        </div>
    );
};
export default withRouter(Manufacturer);
