import {
    legacy_createStore as createStore,
    combineReducers,
    applyMiddleware,
    compose,
} from 'redux';
import createLogger from 'redux-logger';
import thunk from 'redux-thunk';
import reducers from './reducers';
// import { loadUserData } from "../ultis/localStorage";

export default function configureStore() {
    let middleware = [thunk];
    // apply logger middleware in the development environment

    if (process.env.NODE_ENV !== 'production') {
        middleware = [...middleware, createLogger];
    }

    let store;
    //   const userData = loadUserData();
    const persistedData = {
        Auth: {
            //   authenticated: Boolean(userData),
            //   user: userData,
            errors: {},
        },
    };

    if (process.env.NODE_ENV !== 'production') {
        const composeEnhancers =
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        store = createStore(
            combineReducers({
                ...reducers,
            }),
            persistedData,
            composeEnhancers(applyMiddleware(...middleware))
        );
    } else {
        store = createStore(
            combineReducers({
                ...reducers,
            }),
            persistedData,
            applyMiddleware(...middleware)
        );
    }

    return store;
}
