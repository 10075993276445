import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectSupplier = ({ ...props }) => {
    const { data, loading } = useData(`/Supplier`);

    return (
        <Form.Item name="supplierId" label="Nhà cung cấp">
            <Select
                aria-autocomplete="none"
                placeholder="Chọn nhà cung cấp"
                allowClear
                showSearch
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                optionFilterProp="label"
                {...props}
            />
        </Form.Item>
    );
};

export default SelectSupplier;
