import React from 'react';

function ButtonCustom({ textColor, icon, ...props }) {
    return (
        <button
            {...props}
            type="button"
            className={`mr-3 inline-block px-2 py-2 font-bold text-center align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs ${textColor}`}
        >
            {icon}
        </button>
    );
}

export default ButtonCustom;
