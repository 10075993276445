import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../components/api-authorization/AuthorizeService';
import {
    ApplicationPaths,
    QueryParameterNames,
} from './ApiAuthorizationConstants';

function Authentication({ element, path }, props) {
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState({
        ready: false,
        authenticated: false,
    });
    var link = document.createElement('a');
    link.href = path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${
        QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;
    let populateAuthenticationState = async () => {
        const authenticated = await authService.isAuthenticated();
        setAuthenticated({ ready: true, authenticated: authenticated });
    };

    let authenticationChanged = async () => {
        setAuthenticated({ ready: false, authenticated: false });
        populateAuthenticationState();
    };
    useEffect(() => {
        authenticationChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !authenticated.ready ? (
        <div></div>
    ) : authenticated.authenticated ? (
        element
    ) : (
        navigate(redirectUrl)
    );
}

Authentication.propTypes = {};

export default Authentication;
