import {
    Button,
    Cascader,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Spin,
    Tabs,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    createGoodsReceiptNote,
    updateGoodsReceiptNote,
} from '../../redux/actions/goodsReceiptNotes';
import { PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';

import TextArea from 'antd/es/input/TextArea';
// import DetailList from './detailList';
// import SelectEmployee from '../../../components/SelectEmployee';
// import { CARD_TYPES } from '../../../settings/constant';
// import { PAYMENT_METHOD } from '../../../settings/constant';
import client from '../../helpers/client';
import ButtonReload from '../../components/elements/ButtonReload';
import ButtonAdd from '../../components/elements/ButtonAdd';
import apiUrl from '../../settings';
import SelectSupplier from '../../components/SelectSupplier';
import DetailList from './detailList';
import Upload from 'antd/es/upload/Upload';

const GoodsReceiptNoteDetail = ({
    setShowModal,
    showModal,
    currentData,
    createGoodsReceiptNote,
    updateGoodsReceiptNote,
    fetchGoodsReceiptNote,
    // setCurrentData,
    detailId,
    // setdetailId,
}) => {
    const isView = detailId;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({
        receiptDate: dayjs(),
        goodsReceiptNoteDetail: [],
    });
    const fetchGoodsReceiptNoteDetail = async () => {
        if (isView) {
            setLoading(true);
            const { data } = await client().get(
                `${apiUrl}/GoodsReceiptNote/id?id=${detailId}`
            );
            form.setFieldsValue({
                ...data,
                receiptDate: currentData.receiptDate
                    ? dayjs(currentData.receiptDate)
                    : null,
            });
            setDetail({
                ...data,
                receiptDate: dayjs(data.receiptDate),
                goodsReceiptNoteDetail: data.goodsReceiptNoteDetail,
            });
        } else {
            form.setFieldsValue(currentData);
            setDetail({
                receiptDate: currentData.receiptDate
                    ? dayjs(currentData.receiptDate)
                    : null,
                goodsReceiptNoteDetail:
                    currentData.goodsReceiptNoteDetail || [],
            });
        }
        setLoading(false);
    };

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                const allForm = {
                    ...values,
                    receiptDate: detail.receiptDate,
                    goodsReceiptNoteDetail: !isView
                        ? detail.goodsReceiptNoteDetail
                        : undefined,
                };
                setLoading(true);
                const data = !isView
                    ? await createGoodsReceiptNote(allForm, file)
                    : await updateGoodsReceiptNote({
                          ...allForm,
                          id: detailId,
                          customerId: detail.customer?.id,
                      });
                // const data = undefined;
                if (data) {
                    message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                    !isView && setShowModal();
                    // setCurrentData();
                    fetchGoodsReceiptNote();
                    fetchGoodsReceiptNoteDetail();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    useEffect(() => {
        fetchGoodsReceiptNoteDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetForm = () => {
        form.resetFields();
    };

    const handleSaleDateChange = (value) => {
        setDetail({ ...detail, receiptDate: value });
    };
    const [file, setFile] = useState(null);
    const handleFileChange = (info) => {
        if (info.file.status === 'done') {
        } else if (info.file.status === 'error') {
            // Handle error case if needed
        }
    };
    const { TabPane } = Tabs;

    const [activeTab, setActiveTab] = useState(!isView ? '1' : '2');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    const handleDownload = () => {
        // Replace `fileUrl` with the actual URL of the file you want to download
        const fileUrl =
            'https://docs.google.com/spreadsheets/d/1eK4IBGHQRP8LyExsaE-hVeyVgP1I1p_ApBp0y0A_6FE/edit?usp=sharing';

        // Create a link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        // Trigger the download
        link.click();
    };
    return (
        <div>
            <Modal
                title={
                    !isView ? 'Thêm mới đơn nhập kho' : 'Thông tin đơn nhập kho'
                }
                open={showModal}
                centered
                width={1280}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonReload onClick={resetForm} text="Reset" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form form={form} layout="vertical">
                        <div className="mt-6">
                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        label="Ngày nhập kho"
                                        initialValue={dayjs()}
                                    >
                                        <DatePicker
                                            onChange={handleSaleDateChange}
                                            className="w-full"
                                            format={'DD/MM/YYYY'}
                                            value={detail.receiptDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item
                                        name="quantity"
                                        label="Số lượng"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng nhập!',
                                            },
                                        ]}
                                    >
                                        <InputNumber className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} className="mb-1">
                                    <Form.Item
                                        name="vehiclesType"
                                        label="Dòng xe"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng nhập!',
                                            },
                                        ]}
                                    >
                                        <Input className="w-full" />
                                    </Form.Item>
                                </Col>
                                <Col md={12} className="mb-1">
                                    <Form.Item
                                        name="branch"
                                        label="Chi nhánh"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng nhập!',
                                            },
                                        ]}
                                    >
                                        <Input className="w-full" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} md={12} className="mb-1">
                                    <SelectSupplier />
                                </Col>
                                <Col xs={24} md={12} className="mb-1">
                                    <Form.Item name="note" label="Ghi chú">
                                        <TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    <Row gutter={16}>
                        <Col xs={24} md={24} className="mb-1">
                            <Spin spinning={loading}>
                                <Tabs
                                    activeKey={activeTab}
                                    onChange={handleTabChange}
                                >
                                    {!isView && (
                                        <TabPane tab="Excel Import" key="1">
                                            <Button onClick={handleDownload}>
                                                Tải File Mẫu
                                            </Button>
                                            <Upload
                                                onChange={handleFileChange}
                                                beforeUpload={(file) =>
                                                    setFile(file)
                                                }
                                            >
                                                <Button
                                                    icon={<UploadOutlined />}
                                                >
                                                    Chọn File
                                                </Button>
                                            </Upload>
                                        </TabPane>
                                    )}
                                    <TabPane tab="Thêm dữ liệu" key="2">
                                        {!loading && (
                                            <DetailList
                                                masterId={currentData.id}
                                                isView={isView}
                                                setDetail={setDetail}
                                                detail={detail}
                                            />
                                        )}
                                    </TabPane>
                                </Tabs>
                            </Spin>
                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </div>
    );
};

export default connect(null, {
    createGoodsReceiptNote,
    updateGoodsReceiptNote,
})(GoodsReceiptNoteDetail);
