import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import routes from '../helpers/routes';
import { useSelector } from 'react-redux';

function Sidebar(props) {
    const getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    // eslint-disable-next-line no-unused-vars
    const [collapseStates, setCollapseStates] = useState(
        getCollapseStates(routes)
    );
    const { roles } = useSelector((state) => state.auth);

    const getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                routes[i].collapse &&
                getCollapseInitialState(routes[i].views)
            ) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    };
    const activeRoute = (routeName) => {
        const location = window.location;
        const active = location.pathname === routeName ? true : false;
        return active;
    };
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (!prop.view.find((e) => e === roles[0])) {
                return null;
            }
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop['state']] = !collapseStates[prop.state];
                return <></>;
            }
            return (
                <>
                    <li className={'mt-0.5 w-full'} key={key.toString()}>
                        <NavLink
                            to={prop.path}
                            className={
                                activeRoute(prop.path)
                                    ? 'py-2.7 shadow-soft-xl text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg bg-white px-4 font-semibold text-slate-700 transition-colors'
                                    : 'py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 transition-colors'
                            }
                        >
                            {prop.icon ? (
                                <>
                                    <div
                                        className={
                                            activeRoute(prop.path)
                                                ? 'bg-gradient-to-tl from-purple-700 to-pink-500 shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5'
                                                : 'shadow-soft-2xl mr-2 flex h-8 w-8 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5'
                                        }
                                    >
                                        <i
                                            className={
                                                activeRoute(prop.path)
                                                    ? prop.icon + ' text-white'
                                                    : prop.icon
                                            }
                                        />
                                    </div>
                                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
                                        {prop.name}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini">
                                        {prop.mini}
                                    </span>
                                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease-soft">
                                        {prop.name}
                                    </span>
                                </>
                            )}
                        </NavLink>
                    </li>
                </>
            );
        });
    };
    const [width, setWidth] = useState(window.innerWidth);

    const sidebarWrapperRef = useRef(null);

    useEffect(() => {
        const updateDimensions = () => setWidth(window.innerWidth);
        window.addEventListener('resize', updateDimensions);
        if (navigator.platform.indexOf('Win') > -1) {
            const ps = new PerfectScrollbar(sidebarWrapperRef.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });

            return () => {
                ps.destroy();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <aside className="max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-full flex-wrap items-center justify-between  rounded-2xl border-0 bg-white p-0 antialiased shadow-none transition-transform duration-200 xl:left-0 xl:translate-x-0 xl:bg-transparent">
            <div className="h-19.5">
                <i className="absolute top-0 right-0 hidden p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden"></i>
                <NavLink
                    tag={Link}
                    className="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700"
                    to="/"
                >
                    <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand">
                        CRM-SPD
                    </span>
                </NavLink>
            </div>
            <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
            <div
                className="items-center block w-auto max-h-screen overflow-auto  grow basis-full"
                ref={sidebarWrapperRef}
            >
                <ul className="flex flex-col pl-0 mb-0">
                    {createLinks(routes)}
                </ul>
            </div>
        </aside>
    );
}

Sidebar.propTypes = {};

export default Sidebar;
