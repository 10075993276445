import React, { useState } from 'react';
import {
    Col,
    Form,
    Modal,
    InputNumber,
    Spin,
    DatePicker,
    Select,
    message,
    Input,
} from 'antd';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import ButtonCancel from '../../components/elements/ButtonCancel';
import ButtonAdd from '../../components/elements/ButtonAdd';
import client from '../../helpers/client';
import apiUrl from '../../settings';

function ModalSentNumberBike({ setShowModal, showModal, debt, data }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const handleSelectChange = (value) => {
        setType(value);
    };
    const handleCancel = () => {
        setShowModal(false);
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
                customerId: data.customerId,
                orderId: data.id,
            };
            await client()
                .post(`${apiUrl}/ProcessingPayment`, transformData)
                .then((data) => {
                    if (data.request.status === 200) {
                        message.success('Thêm thành công !');
                    }
                    handleCancel();
                });
        });
    };
    return (
        <Modal
            title="Thanh toán hoá đơn"
            open={showModal}
            centered
            width={800}
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd onClick={handleOk} text="Lưu" />
                    </div>
                </>
            }
        >
            <Spin spinning={loading}>
                <div className="flex text-xl">
                    <p className="font-bold ">Dư nợ hiện tại</p>:{' '}
                    <p className="px-1">
                        {`${debt}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                            ' VNĐ'}
                    </p>
                </div>
                <Form form={form} layout="vertical">
                    <Col md={24} className="mb-1">
                        <Form.Item
                            name="noteType"
                            label="Loại"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Chọn loại"
                                optionFilterProp="label"
                                showSearch
                                dropdownMatchSelectWidth={false}
                                onChange={handleSelectChange}
                                options={[
                                    {
                                        value: 0,
                                        label: 'Cavet',
                                    },
                                    {
                                        value: 1,
                                        label: 'Biển số',
                                    },
                                    {
                                        value: 2,
                                        label: 'Khác',
                                    },
                                ]}
                            />
                        </Form.Item>
                        {type === 1 && (
                            <Form.Item label="Biển số" name="licensePlate">
                                <Input />
                            </Form.Item>
                        )}

{type !== 2  && ( <Form.Item
                            label="Ngày gửi"
                            initialValue={dayjs()}
                            name="deliveryDate"
                        >
                            <DatePicker
                                className="w-full"
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>    )}

                        <Col md={24} className="mb-1">
                            <Form.Item
                                name="paymentAmount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                                label="Số tiền thanh toán nợ"
                            >
                                <InputNumber
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                    }
                                    className="w-full"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} className="mb-1">
                            <Form.Item name="note" label="nội dung">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Col>
                </Form>
            </Spin>
        </Modal>
    );
}

ModalSentNumberBike.propTypes = {};

export default ModalSentNumberBike;
