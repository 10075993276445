import { Col, Form, Input, message, Row } from 'antd';
import React from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings';
export function PasswordForm() {
    const [form] = Form.useForm();
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
            };
            const data = await client().post(
                `${apiUrl}/Auth/ChangePassword`,
                transformData
            );

            if (data) {
                message.success('thay đổi mật khẩu thành công');
                form.resetFields();
            }
        });
    };
    return (
        <div
            className="relative flex flex-col min-w-0 mt-6 break-words bg-white border-0 dark:bg-gray-950 dark:shadow-soft-dark-xl shadow-soft-xl rounded-2xl bg-clip-border"
            id="password"
        >
            <div className="p-6 mb-0 rounded-t-2xl">
                <h5 className="dark:text-white">Đổi mật khẩu</h5>
            </div>
            <div className="flex-auto p-6 pt-0">
                <Form form={form} layout="vertical">
                    <Row gutter={16}>
                        <Col md={24} className="mb-1">
                            <Form.Item
                                name="oldPassword"
                                label="Mật khẩu hiện tại"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Nhập mật khẩu cũ" />
                            </Form.Item>
                        </Col>
                        <Col md={24} className="mb-1">
                            <Form.Item
                                name="newPassword"
                                label="Mật khẩu mới"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Nhận mật khẩu mới" />
                            </Form.Item>
                        </Col>
                        <Col md={24} className="mb-1">
                            <Form.Item
                                name="reNewPassword"
                                label="Xác nhận mật khẩu mới"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('newPassword') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Hai mật khẩu bạn đã nhập không khớp!'
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Xác nhận mật khẩu mới" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <h5 className="mt-12 dark:text-white">Yêu cầu đặt mật khẩu</h5>
                <p className="mb-2 text-slate-500 dark:text-white/60">
                    Vui lòng làm theo hướng dẫn này để có mật khẩu mạnh:
                </p>
                <ul className="float-left pl-6 mb-0 list-disc text-slate-500">
                    <li>
                        <span className="leading-normal text-sm">
                            Một ký tự đặc biệt
                        </span>
                    </li>
                    <li>
                        <span className="leading-normal text-sm">
                            Tối thiểu 6 ký tự
                        </span>
                    </li>
                    <li>
                        <span className="leading-normal text-sm">
                            Một số (2 được khuyến nghị)
                        </span>
                    </li>
                    <li>
                        <span className="leading-normal text-sm">
                            Thay đổi nó thường xuyên
                        </span>
                    </li>
                </ul>
                <button
                    className="inline-block float-right px-8 py-2 mt-16 mb-0 font-bold text-right text-white uppercase align-middle transition-all border-0 rounded-lg cursor-pointer hover:scale-102 active:opacity-85 hover:shadow-soft-xs dark:bg-gradient-to-tl dark:from-slate-850 dark:to-gray-850 bg-gradient-to-tl from-gray-900 to-slate-800 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25"
                    onClick={handleOk}
                >
                    Cập nhật mật khẩu
                </button>
            </div>
        </div>
    );
}
