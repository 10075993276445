import { DatePicker, Input, message, Modal, Table, Tag, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import client from '../../helpers/client';
import apiUrl from '../../settings/index';
import withRouter from '../../helpers/withRouter';
import LicensePlateServiceFormDetail from './LicensePlateServiceFormDetail';
import dayjs from 'dayjs';
import PaymentModal from './PaymentModal';
import { getPartners } from '../../redux/actions/partner';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { createConfirmationModal, getFullName } from '../../helpers/utility';
import ExportToExcel from '../Order/ExportToExcel';
import { createActionColumn, createAddressColumn, createCardTypeColumn, createCustomerNameColumn, createDeliveryCavetDateColumn, createIsPayColumn, createLicensePlateColumn, createNoteColumn, createPartnerColumn, createProductColumn, createSentDateColumn } from './conlums';

const headers = [
  'Tên đối tác',
  "Ngày gửi",
  'Tên Khách Hàng',
  'Loại giấy tờ',
  'Loại xe',
  'Biển số',
  'Ghi chú',
  'Tình trạng thanh toán',

];
const { RangePicker } = DatePicker;
const LicensePlateService = () => {
  const partnerList = useSelector((state) => state.Partner.data);
  const { roles } = useSelector((state) => state.auth);
  const [execlData, setExcelData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPartners());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getCardType(params) {
    switch (params) {
      case 0:
        return (
          <Tag color={'success'}>
            {'Chứng minh gốc'.toUpperCase()}
          </Tag>
        );
      case 1:
        return (
          <Tag color={'default'}>{'Chứng photo'.toUpperCase()}</Tag>
        );
      case 2:
        return <Tag color={'warning'}>{'Không'.toUpperCase()}</Tag>;
      default:
        return (
          <Tag color={'volcano'}>
            {'Chứng minh gốc'.toUpperCase()}
          </Tag>
        );
    }
  }
  function getCardTypeExcel(params) {
    switch (params) {
      case 0:
        return (
          'Chứng minh gốc'
        );
      case 1:
        return (
          'Chứng photo'
        );
      case 2:
        return 'Không';
      default:
        return (
          'Chứng minh gốc'
        );
    }
  }

  const handleDelete = (e, record) => {
    e.stopPropagation();
    createConfirmationModal('Xác nhận', 'Bạn có muốn xóa sản phẩm này ?', async () => {
      const { data } = await client().delete(
        `${apiUrl}/LicensePlateService/DeleteLicensePlateServiceById/${record.id}/${record?.product?.id}`
      );
      if (data) message.success('Đã xóa');
      fetchLicensePlateService();
    });
  };

  const handleRedpoint = (e, record) => {
    e.stopPropagation();
    createConfirmationModal('Xác nhận', !record.redpoint ? 'ĐÁNH DẤU ?' : 'BỎ ĐÁNH DẤU ?', async () => {
      await client().put(`${apiUrl}/LicensePlateService/UpdateRedpoint/${record.id}`);
      message.success('Chỉnh sửa thành công');
      fetchLicensePlateService();
    });
  };

  const internalResend = (e, record) => {
    e.stopPropagation();
    createConfirmationModal('Xác nhận', 'Gửi lại hồ sơ ?', async () => {
      const { data } = await client().put(
        `${apiUrl}/LicensePlateService/InternalReSend/${record.id}`
      );
      if (data) {
        message.success('Đã gửi lại hồ sơ');
        fetchLicensePlateService();
      }
    });
  };

  const paymentForPartner = (e, record) => {
    setOrderId(record.id);
    e.stopPropagation();
    if (!record.isPay) {
      setIsPaymentModalOpen(true);
    } else {
      const content = 'Bạn có muốn huỷ thanh toán ?';
      createConfirmationModal('Xác nhận', content, async () => {
        const { data } = await client().put(
          `${apiUrl}/LicensePlateService/CancelPaymentForPartner/${record.id}`
        );
        if (data) {
          message.success(`Huỷ thanh toán thành công`);
          fetchLicensePlateService();
        }
      });
    }
  };
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [orderId, setOrderId] = useState(0);

  const handleCancel = () => {
    setIsPaymentModalOpen(false);
  };
  const [licensePlateServices, setLicensePlateServices] = useState();
  const [filterLicensePlateServices, setFilterLicensePlateServices] =
    useState();
  const [currentData, setCurrentData] = useState(false);
  const fetchLicensePlateService = async () => {
    const { data } = await client().get(
      `${apiUrl}/LicensePlateService/GetAllLicensePlateService`
    );
    setLicensePlateServices(data);
    setExcelData(transformDataForExcel(data));
  };
  const handleRowClick = (record, index, event) => {
    if (event.target.tagName !== 'A') {
      // handle row click event
      setCurrentData({
        ...record,
      });
    }
  };

  useEffect(() => {
    fetchLicensePlateService();
  }, []);
  const search = (e) => {
    const dataToFilter = _.map(licensePlateServices, (item) => {
      return {
        ...item,
        partnerName: getFullName(
          _.get(item, 'partner.lastName'),
          _.get(item, 'partner.firstName')
        ),
        customerName: getFullName(
          _.get(item, 'customer.lastName'),
          _.get(item, 'customer.firstName')
        ),
      };
    });
    const value = e.target.value;
    const filterTable = dataToFilter.filter((o) => {
      return Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      );
    });
    if (!value) setFilterLicensePlateServices();
    else {
      setFilterLicensePlateServices(filterTable);
      setExcelData(transformDataForExcel(filterTable));
    }
  };

  const handleDateChange = (values) => {
    if (!values) return setFilterLicensePlateServices(licensePlateServices);
    const [start, end] = values;
    const s = dayjs(start).format('YYYY-MM-DD');
    const e = dayjs(end).format('YYYY-MM-DD');
    const filterDates = _.filter(licensePlateServices, (item) => {
      return dayjs(item.saleDate).isBetween(s, e, '', '[]');
    });
    setFilterLicensePlateServices(filterDates);
    setExcelData(transformDataForExcel(filterDates));
  };


  const onChange = (e) => {
    if (!e.target.checked) return setFilterLicensePlateServices(licensePlateServices);
    const filterDates = _.filter(licensePlateServices, (item) => {
      return item.isRedPoint === true;
    });

    setFilterLicensePlateServices(filterDates);
    setExcelData(transformDataForExcel(filterDates));
  };
  const transformDataForExcel = (data) => {
    return data.map(item => {
      let pay;
      if (item.isPay && item.paymentAmount !== 0) {
        pay = `${item.paymentAmount.toLocaleString()} VNĐ`;
      } else {
        pay = 'Chưa';
      }
      return {
        partnerName: _.get(item, 'partner.firstName'),
        sentDate: _.get(item, 'sentDate'),
        customerName: `${_.get(item, 'customer.lastName')} ${_.get(item, 'customer.firstName')}`,
        cardType: getCardTypeExcel(_.get(item, 'cardType')),
        productName: _.get(item, 'product.name'),
        licensePlate: _.get(item, 'licensePlate'),
        note: _.get(item, 'note'),
        pay: pay
      };
    });
  };

  const columns = [
    createPartnerColumn(partnerList),
    createSentDateColumn(),
    createCustomerNameColumn(),
    createAddressColumn(),
    createCardTypeColumn(getCardType),
    createProductColumn(),
    createLicensePlateColumn(),
    createDeliveryCavetDateColumn(),
    createNoteColumn(),
    createIsPayColumn(roles),
    createActionColumn(roles, handleRedpoint, paymentForPartner, internalResend, handleDelete),
  ];

  return (
    <div>
      <div className="font-bold text-blue-500">Dịch vụ biển số</div>
      <div className="my-4">
        <div className="flex justify-start mb-4">
          <Input
            className="w-64 mt-4"
            placeholder="Lọc ..."
            onChange={search}
          />

        </div>
        <RangePicker
          onChange={handleDateChange}
        // disabledDate={disabledDate}

        />
        <Checkbox className='ml-2' onChange={onChange}>Đơn hàng đánh dấu</Checkbox>
      </div>
      <div className="col-md-4 center">
        <ExportToExcel
          data={execlData}
          fileName={'dich-vu-bien-so'}
          headers={headers}
        />
      </div>
      <Table
        dataSource={
          !filterLicensePlateServices
            ? licensePlateServices
            : filterLicensePlateServices
        }
        columns={columns}
        pagination={{ pageSize: 10 }}
        onRow={(record, index) => ({
          onClick: (event) => handleRowClick(record, index, event),
        })}
      />

      {currentData && (
        <LicensePlateServiceFormDetail
          setShowModal={setCurrentData}
          showModal={currentData}
          currentData={currentData}
          fetchLicensePlateService={fetchLicensePlateService}
        />
      )}
      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        onClose={handleCancel}
        id={orderId}
        fetchData={fetchLicensePlateService}
      />
    </div>
  );
};
export default withRouter(LicensePlateService);
