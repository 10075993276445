import {
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import ButtonCancel from '../../../components/elements/ButtonCancel';
import { uid } from '../../../helpers/utility';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import SelectProduct from '../../../components/SelectProduct';
import SelectBankInfo from '../../../components/SelectBankInfo';
import { PAYMENT_METHOD } from '../../../settings/constant';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { GIAM_DOC, NHAN_VIEN } from '../../../helpers/roles';
import client from '../../../helpers/client';
import apiUrl from '../../../settings';

const IS_BANK = 0;
const FormAddDetail = ({
    openAddDetail,
    setOpenAddDetail,
    currentData,
    setList,
    list,
    isViewOrder,
    setCurrentData,
}) => {
    console.log('🚀 ~ file: formAddDetail.js:37 ~ currentData:', currentData);
    const isView = !_.isEmpty(currentData);
    const { roles } = useSelector((state) => state.auth);
    const [isInvoice, setIsInvoice] = useState(false);
    const [form] = Form.useForm();
    const [selectProduct, setSelectProduct] = useState(
        _.get(currentData, 'product')
    );
    const [selectBankInfo, setSelectBankInfo] = useState(
        _.get(currentData, 'bankInfo')
    );
    const [selectPaymentMethod, setSelectPaymentMethod] = useState(
        _.get(currentData, 'paymentmethod')
    );
    const [deliveryDate, setDeliveryDate] = useState(
        _.get(currentData, 'deliveryDate') && dayjs(currentData.deliveryDate)
    );
    const [npDeliveryDate, setNPDeliveryDate] = useState(
        _.get(currentData, 'npDeliveryDate') &&
            dayjs(currentData.npDeliveryDate)
    );

    const fetchIsInvoice = async (id) => {
        const { data } = await client().get(
            `${apiUrl}/ProcessingPayment/GetProcessingPaymentByOrderId/` +
                currentData.orderID
        );
        setIsInvoice(data);
    };
    useEffect(() => {
        fetchIsInvoice(currentData.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleCancel = () => {
        setOpenAddDetail(false);
        setCurrentData();
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            // const values = form.getFieldsValue();
            const orderDetailId = uid();
            if (isView) {
                const newData = _.map(list, (item) => {
                    if (item.orderDetailId === currentData.orderDetailId) {
                        return {
                            ...values,
                            product: selectProduct,
                            bankInfo:
                                selectPaymentMethod === IS_BANK
                                    ? selectBankInfo
                                    : null,
                            orderDetailId: currentData.orderDetailId,
                            deliveryDate:
                                deliveryDate &&
                                dayjs(deliveryDate).format('YYYY-MM-DD'),
                            npDeliveryDate:
                                npDeliveryDate &&
                                dayjs(npDeliveryDate).format('YYYY-MM-DD'),
                        };
                    }
                    return item;
                });

                setList(newData);
            } else {
                list.push({
                    ...values,
                    orderDetailId,
                    product: selectProduct,
                    bankInfo: selectBankInfo,
                });
                setList(list);
            }
            setOpenAddDetail(false);
        });
    };

    const handleNPDeliveryDate = (value) => {
        setNPDeliveryDate(value);
    };

    const handleSaleDateChange = (value) => {
        setDeliveryDate(value);
    };

    const calculateDebt = () => {
        const {
            debtBank = 0,
            priceOrder = 0,
            actuallyReceived = 0,
        } = form.getFieldsValue();
        return selectPaymentMethod === IS_BANK
            ? parseInt(priceOrder) -
                  parseInt(debtBank) -
                  parseInt(actuallyReceived)
            : parseInt(priceOrder) - parseInt(actuallyReceived);
    };

    const handleActuallyReceivedChange = (e) => {
        const result = calculateDebt();
        form.setFieldsValue({
            debt: isNaN(result) ? 0 : result,
        });
    };

    const handleDebtBankChange = (e) => {
        const result = calculateDebt();
        form.setFieldsValue({
            debt: isNaN(result) ? 0 : result,
        });
    };

    const handlePriceOrderChange = (e) => {
        const result = calculateDebt();
        form.setFieldsValue({
            debt: isNaN(result) ? 0 : result,
        });
    };

    const handleSelectProduct = (product) => {
        if (!product) return;
        setSelectProduct(product);
        const priceOrder = parseInt(
            !_.isEmpty(currentData) ? currentData.priceOrder : product.price
        );
        // const debt = calculateDebt();
        form.setFieldsValue({
            productId: product.id,
            priceOrder: isNaN(priceOrder) ? 0 : priceOrder,
            // debt,
        });
    };
    const checkDebt = () => {
        if (roles[0] === GIAM_DOC) {
            return false;
        }
        if (isInvoice) {
            return true;
        }
    };
    return (
        <Modal
            title={
                isView || isViewOrder
                    ? 'Thông tin chi tiết  đơn hàng'
                    : 'Thêm mới chi tiết'
            }
            open={openAddDetail}
            width={600}
            centered
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        <ButtonAdd
                            icon={
                                isView ? (
                                    <SaveOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                ) : (
                                    <PlusOutlined
                                        style={{ fontSize: '15px' }}
                                    />
                                )
                            }
                            onClick={handleOk}
                            text={isView || isViewOrder ? 'Lưu' : 'Tạo'}
                        />
                    </div>
                </>
            }
        >
            <Form initialValues={currentData} layout="vertical" form={form}>
                <Row gutter={16}>
                    <Col md={12} className="mb-1">
                        <SelectProduct
                            form={form}
                            handleSelectProduct={handleSelectProduct}
                            value={selectProduct}
                            isView={isView}
                            currentData={currentData}
                        />
                    </Col>
                    <Col xs={24} md={12} className="mb-1">
                        <Form.Item name="note" label="Màu xe">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} className="mb-1">
                        <ul className="pl-0 mx-auto mb-4 list-none">
                            <li className="relative block px-4 py-2 pt-0 pl-0 text-sm leading-normal bg-white border-0 rounded-t-lg text-inherit">
                                <strong className="text-slate-700">
                                    Số máy:
                                </strong>{' '}
                                {selectProduct
                                    ? selectProduct.engineNumber
                                    : ''}
                            </li>
                            <li className="relative block px-4 py-2 pt-0 pl-0 text-sm leading-normal bg-white border-0 rounded-t-lg text-inherit">
                                <strong className="text-slate-700">
                                    Số khung:
                                </strong>{' '}
                                {selectProduct ? selectProduct.frameNumber : ''}
                            </li>
                            <li className="relative block px-4 py-2 pt-0 pl-0 text-sm leading-normal bg-white border-0 rounded-t-lg text-inherit">
                                <strong className="text-slate-700">
                                    Màu xe:
                                </strong>{' '}
                                {selectProduct ? selectProduct.color : ''}
                            </li>
                        </ul>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col
                        md={selectPaymentMethod === IS_BANK ? 12 : 24}
                        className="mb-1"
                    >
                        <Form.Item
                            name="paymentmethod"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền !',
                                },
                            ]}
                            label="Hình thức mua"
                        >
                            <Select
                                allowClear
                                placeholder="Chọn hình thức mua"
                                optionFilterProp="label"
                                showSearch
                                onChange={(id) => setSelectPaymentMethod(id)}
                                dropdownMatchSelectWidth={false}
                                options={_.map(PAYMENT_METHOD, (item) => ({
                                    value: item.id,
                                    label: item.name,
                                }))}
                                // {...props}
                            />
                        </Form.Item>
                    </Col>
                    {selectPaymentMethod === IS_BANK ? (
                        <Col md={12} className="mb-1">
                            <SelectBankInfo
                                setSelectBankInfo={setSelectBankInfo}
                            />
                        </Col>
                    ) : (
                        ''
                    )}
                </Row>

                <Row gutter={16}>
                    <Col xs={24} md={12} className="mb-1">
                        <Form.Item
                            name="priceOrder"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền !',
                                },
                            ]}
                            label="Giá"
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                onChange={handlePriceOrderChange}
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền !',
                                },
                            ]}
                            name="actuallyReceived"
                            label="Thực nhận"
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                className="w-full"
                                onChange={handleActuallyReceivedChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {selectPaymentMethod === IS_BANK ? (
                        <Col md={12} className="mb-1">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Vui lòng điền !',
                                    },
                                ]}
                                name="debtBank"
                                label="Tiền nợ ngân hàng"
                            >
                                <InputNumber
                                    formatter={(value) =>
                                        `${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                    }
                                    onChange={handleDebtBankChange}
                                    className="w-full"
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        ''
                    )}
                    <Col md={12} className="mb-1">
                        <Form.Item
                            name="debt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền !',
                                },
                            ]}
                            label="Tiền nợ cửa hàng"
                        >
                            <InputNumber
                                formatter={(value) =>
                                    `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                disabled={checkDebt()}
                                className="w-full"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    {isView && (
                        <>
                            <Col xs={24} md={12} className="mb-1">
                                <Form.Item label="Ngày giao cavet">
                                    <DatePicker
                                        onChange={handleSaleDateChange}
                                        className="w-full"
                                        format={'DD/MM/YYYY'}
                                        value={
                                            deliveryDate ? deliveryDate : null
                                        }
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} className="mb-1">
                                <Form.Item label="Ngày giao biển số">
                                    <DatePicker
                                        onChange={handleNPDeliveryDate}
                                        className="w-full"
                                        format={'DD/MM/YYYY'}
                                        value={
                                            npDeliveryDate
                                                ? npDeliveryDate
                                                : null
                                        }
                                        disabled={true}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </Form>
        </Modal>
    );
};

export default memo(FormAddDetail);
