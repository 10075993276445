import React from 'react';
import {
    Form,
    message,
    Modal,
    Row,
    Spin,
    Col,
    Input,
    DatePicker,
    Select,
    Checkbox,
} from 'antd';
import { useState } from 'react';
import ButtonCancel from '../../components/elements/ButtonCancel';
import ButtonAdd from '../../components/elements/ButtonAdd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { updateLicensePS } from '../../redux/actions/licensePlateService';
import { connect, useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CARD_TYPES } from '../../settings/constant';
import { GIAM_DOC, NHAN_VIEN } from '../../helpers/roles';
import SelectCustomer from '../../components/SelectCustomer';

function LicensePlateServiceFormDetail({
    setShowModal,
    showModal,
    currentData = {},
    updateLicensePS,
    fetchLicensePlateService,
}) {
    const [form] = Form.useForm();
    const { roles } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const isView = currentData.id;
    const handleCancel = () => {
        setShowModal(false);
    };
    const handleOk = () => {
        form.validateFields().then(async (values) => {
            const transformData = {
                ...values,
            };

            setLoading(true);
            const data = await updateLicensePS({
                ...transformData,
                id: currentData.id,
            });
            if (data) {
                message.success('Đã cập nhật');
                setShowModal();
                fetchLicensePlateService();
            }
            setLoading(false);
        });
    };

    return (
        <Modal
            title={'Chỉnh sửa thông tin dịch vụ biển số'}
            open={showModal}
            centered
            width={800}
            onCancel={handleCancel}
            footer={
                <>
                    <div>
                        <ButtonCancel onClick={handleCancel} text="Đóng" />
                        {(roles[0] === GIAM_DOC || roles[0] === NHAN_VIEN) && (
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        )}
                    </div>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    initialValues={{
                        ...currentData,
                        sentDate: currentData?.sentDate
                            ? dayjs(currentData?.sentDate)
                            : undefined,
                        deliveryDate: currentData?.deliveryDate
                            ? dayjs(currentData?.deliveryDate)
                            : undefined,
                        deliveryCavetDate: currentData?.deliveryCavetDate
                            ? dayjs(currentData?.deliveryCavetDate)
                            : undefined,
                    }}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={8} className="mb-1">
                            <SelectCustomer disabled={true} />
                        </Col>
                        <Col xs={24} md={8} className="mb-1">
                            <Form.Item label="Ngày gửi dịch vụ" name="sentDate">
                                <DatePicker
                                    className="w-full"
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8} className="mb-1">
                            <Form.Item
                                label="Ngày nhận biển số"
                                name="deliveryDate"
                            >
                                <DatePicker
                                    className="w-full"
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8} className="mb-1">
                            <Form.Item name="licensePlate" label="Biển số ">
                                <Input className="w-full" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} className="mb-1">
                            <Form.Item
                                label="Ngày nhận cavet"
                                name="deliveryCavetDate"
                            >
                                <DatePicker
                                    className="w-full"
                                    format={'DD/MM/YYYY'}
                                />
                            </Form.Item>
                            <Form.Item
                                name="isDelivery"
                                valuePropName="checked"
                            >
                                <Checkbox>Đã nhận cavet</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col md={12} className="mb-1">
                            <Form.Item
                                name="cardType"
                                label="Loại giấy tờ đính kèm"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại giấy tờ"
                                    optionFilterProp="label"
                                    showSearch
                                    dropdownMatchSelectWidth={false}
                                    options={_.map(CARD_TYPES, (item) => ({
                                        value: item.id,
                                        label: item.name,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} className="mb-1">
                            <Form.Item name="note" label="Ghi chú">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}
const mapStateToProps = (state) => {
    return {
        partner: state.Partner,
    };
};
export default connect(mapStateToProps, {
    updateLicensePS,
})(LicensePlateServiceFormDetail);
