import { GIAM_DOC, NHAN_VIEN } from './roles';

var routes = [
    {
        path: '/',
        name: 'Dashboard',
        icon: 'fa fa-city ',
        view: [GIAM_DOC],
    },
    {
        path: '/order',
        name: 'Quản Lý Đơn hàng',
        icon: 'fa fa-clipboard ',
        view: [NHAN_VIEN, GIAM_DOC],
    },
    {
        path: '/goods-receipt-note',
        name: 'Quản lý nhập kho',
        icon: 'fa fa-clipboard ',
        view: [NHAN_VIEN, GIAM_DOC],
    },

    {
        path: '/product',
        name: 'Sản phẩm',
        icon: 'fa fa-motorcycle ',
        view: [NHAN_VIEN, GIAM_DOC],
    },
    {
        path: '/customer',
        name: 'Danh Sách Khách hàng',
        icon: 'fa fa-user',
        view: [NHAN_VIEN, GIAM_DOC],
    },
    {
        path: '/employee',
        name: 'Nhân viên',
        icon: 'fa fa-clipboard-user ',
        view: [GIAM_DOC],
    },
    {
        path: '/partner',
        name: 'Đôi tác dịch vụ biển số',
        icon: 'fa fa-address-card ',
        view: [GIAM_DOC],
    },
    {
        path: '/license',
        name: 'Dịch vụ biển số',
        icon: 'fa fa-address-card ',
        view: [NHAN_VIEN, GIAM_DOC],
    },
    {
        path: '/supplier',
        name: 'Nhà cung cấp',
        icon: 'fa fa-address-card ',
        view: [NHAN_VIEN, GIAM_DOC],
    },
];
export default routes;
