import { Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { createCustomer, updateCustomer } from '../../redux/actions/customer';
// import {formatter} from "../../helpers/utility"
import ButtonAdd from '../../components/elements/ButtonAdd';
import ButtonCancel from '../../components/elements/ButtonCancel';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import Subdivision from '../../components/Subdivision';
import TextArea from 'antd/es/input/TextArea';
import CustomerCreate from '../../components/CustomerCreate';

const CustomerDetail = ({
    setShowModal,
    showModal,
    currentData = {},
    createCustomer,
    updateCustomer,
    fetchCustomer,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState(currentData);
    const [isView, setIsView] = useState(defaultData.id);
    useEffect(() => {
        setIsView(defaultData.id);
    }, [defaultData]);

    const handleOk = () => {
        form.validateFields()
            .then(async (values) => {
                setLoading(true);
                const data = !isView
                    ? await createCustomer(values)
                    : await updateCustomer({ ...values, id: currentData.id });
                if (data) {
                    message.success(!isView ? 'Đã thêm' : 'Đã cập nhật');
                    !isView && setShowModal();
                    fetchCustomer();
                }
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <Modal
                title={
                    isView
                        ? 'Chỉnh sửa thông tin khách hàng'
                        : 'Thêm mới khách hàng'
                }
                open={showModal}
                centered
                width={800}
                onCancel={handleCancel}
                footer={
                    <>
                        <div>
                            <ButtonCancel onClick={handleCancel} text="Đóng" />
                            <ButtonAdd
                                icon={
                                    isView ? (
                                        <SaveOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    ) : (
                                        <PlusOutlined
                                            style={{ fontSize: '15px' }}
                                        />
                                    )
                                }
                                onClick={handleOk}
                                text={isView ? 'Lưu' : 'Tạo'}
                            />
                        </div>
                    </>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={defaultData}
                        layout="vertical"
                    >
                        <div className="mt-6">
                            {!isView ? (
                                <CustomerCreate
                                    onChange={(e) => setDefaultData(e)}
                                />
                            ) : (
                                <>
                                    <Row gutter={16}>
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            className="mb-1"
                                        >
                                            <Form.Item
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Vui lòng điền !',
                                                //     },
                                                // ]}
                                                name="lastName"
                                                label="Họ"
                                            >
                                                <Input className="w-full" />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            className="mb-1"
                                        >
                                            <Form.Item
                                                name="firstName"
                                                label="Tên"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Vui lòng điền !',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            className="mb-1"
                                        >
                                            <Form.Item
                                                name="phoneNumber1"
                                                label="Số điện thoại 1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Vui lòng điền !',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            className="mb-1"
                                        >
                                            <Form.Item
                                                name="phoneNumber2"
                                                label="Số điện thoại 2"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Vui lòng điền !',
                                                //     },
                                                // ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col
                                            xs={24}
                                            sm={12}
                                            md={12}
                                            className="mb-1"
                                        >
                                            <Form.Item
                                                name="identityCard"
                                                label="Số cccd"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Subdivision
                                            form={form}
                                            data={defaultData}
                                        />
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={12} md={24} className="mb-1">
                                            <Form.Item
                                                name="detailedAddress"
                                                label="Chi tiết địa chỉ"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24} className="mb-1">
                                            <Form.Item
                                                name="note"
                                                label="Ghi chú"
                                            >
                                                <TextArea />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </div>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

export default connect(null, {
    createCustomer,
    updateCustomer,
})(CustomerDetail);
