export const ACTION = {
    CREATE: 'create',
    READ: 'read',
    DELETE: 'delete',
    UPDATE: 'update',
};
export const CARD_TYPES = [
    {
        id: 0,
        name: 'CCCD gốc',
    },
    {
        id: 1,
        name: 'CCCD coppy',
    },
    {
        id: 2,
        name: 'Không kèm giấy tờ',
    },
];

export const CAVET_OPTION = [
    {
        value: true,
        name: 'Đã nhận',
    },
    {
        vale: false,
        name: 'Chưa nhận',
    },
];

export const PAYMENT_METHOD = [
    {
        id: 0,
        name: 'Ngân hàng',
    },
    {
        id: 1,
        name: 'Tiền mặt',
    },
];
