import { Form, Select } from 'antd';
import _ from 'lodash';
import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { getBankInfos } from '../redux/actions/bankInfo';

const SelectBank = ({
    getBankInfos,
    bankinfos,
    required = true,
    label = 'Ngân hàng',
    className,
    ...props
}) => {
    useEffect(() => {
        getBankInfos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form.Item
            name="bankInfoId"
            label={label}
            rules={[
                {
                    required: required,
                    message: 'Vui lòng chọn!',
                },
            ]}
        >
            <Select
                allowClear
                placeholder="Chọn ngân hàng"
                optionFilterProp="label"
                showSearch
                dropdownMatchSelectWidth={false}
                loading={bankinfos.isFetching}
                options={_.map(bankinfos.data, (item) => ({
                    value: item.name,
                    label: item.name,
                }))}
                {...props}
            />
        </Form.Item>
    );
};

const mapStateToProps = (state) => {
    return {
        bankinfos: state.BankInfo,
    };
};
export default connect(mapStateToProps, {
    getBankInfos,
})(memo(SelectBank));
