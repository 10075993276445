import { Form, Select } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPartners } from '../redux/actions/partner';

const SelectPartner = ({ getPartners, partners, ...props }) => {
    useEffect(() => {
        getPartners();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form.Item
            name="partnerId"
            label="Đối tác"
            rules={[
                {
                    required: true,
                    message: 'Vui lòng chọn!',
                },
            ]}
        >
            <Select
                allowClear
                placeholder="Chọn nhân đối tác"
                optionFilterProp="label"
                showSearch
                dropdownMatchSelectWidth={false}
                loading={partners.isFetching}
                options={_.map(partners?.data, (item) => ({
                    value: item.id,
                    label: item.lastName + ' ' + item.firstName,
                }))}
                {...props}
            />
        </Form.Item>
    );
};

const mapStateToProps = (state) => {
    return {
        partners: state.Partner,
    };
};
export default connect(mapStateToProps, {
    getPartners,
})(SelectPartner);
