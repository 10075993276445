import { Form, Select } from 'antd';
import _ from 'lodash';

import { useData } from '../helpers/hooks';

const SelectDistrict = ({ provinceId, ...props }) => {
    const { data, loading } = useData(
        provinceId && `/Province/GetAllDistrictByProvinceId/${provinceId}`
    );
    return (
        <Form.Item
            name="districtId"
            label="Quận / Huyện"
            // rules={[
            //     {
            //         required: true,
            //         message: 'Vui lòng chọn!',
            //     },
            // ]}
        >
            <Select
                aria-autocomplete="none"
                placeholder="Chọn Quận / Huyện"
                allowClear
                showSearch
                loading={loading}
                options={_.map(data, (item) => ({
                    value: item.id,
                    label: item.name,
                }))}
                optionFilterProp="label"
                {...props}
            />
        </Form.Item>
    );
};

export default SelectDistrict;
