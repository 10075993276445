import { Modal } from 'antd';
export function formatter(value) {
  return value !== null && value !== undefined
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '';
}

export function formatterChart(value) {
  return value !== null && value !== undefined
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : '';
}
export function convertNumberToWords(number) {
  var ones = [
    '',
    'một',
    'hai',
    'ba',
    'bốn',
    'năm',
    'sáu',
    'bảy',
    'tám',
    'chín',
    'mười',
    'mười một',
    'mười hai',
    'mười ba',
    'mười bốn',
    'mười năm',
    'mười sáu',
    'mười bảy',
    'mười tám',
    'mười chín',
  ];
  var tens = [
    '',
    '',
    'hai mươi',
    'ba mươi',
    'bốn mươi',
    'năm mươi',
    'sáu mươi',
    'bảy mươi',
    'tám mươi',
    'chín mươi',
  ];
  var groups = ['', 'nghìn', 'triệu', 'tỷ', 'nghìn tỷ', 'triệu tỷ'];
  var words = '';

  if (number === 0) {
    return 'không đồng';
  }

  if (number < 0) {
    words += 'âm ';
    number = Math.abs(number);
  }

  for (var i = 0; i < groups.length; i++) {
    var group = Math.floor(number % 1000);
    number = Math.floor(number / 1000);

    if (group !== 0) {
      var groupWords = '';

      if (group < 20) {
        groupWords = ones[group];
      } else if (group < 100) {
        groupWords =
          tens[Math.floor(group / 10)] + ' ' + ones[group % 10];
      } else {
        groupWords =
          ones[Math.floor(group / 100)] +
          ' trăm ' +
          tens[Math.floor((group % 100) / 10)] +
          ' ' +
          ones[group % 10];
      }

      if (i === 0) {
        groupWords += 'đồng';
      } else {
        groupWords += ' ' + groups[i];
      }

      words = groupWords + ' ' + words;
    }
  }

  return words.trim();
}
export function uid() {
  return String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ''
  );
}

export function getFullName(lastName = ' ', firstName = ' ') {
  if (!lastName || !firstName) return lastName || firstName;
  return lastName + ' ' + firstName;
}
export function numberToVietnameseWords(number) {
  if (number === 0) {
    return 'không';
  }

  var units = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  var result = '';

  if (number < 0) {
    result += 'âm ';
    number = Math.abs(number);
  }

  if (number >= 1e12) {
    return number.toString();
  }

  if (number >= 1e9) {
    var billions = Math.floor(number / 1e9);
    result += numberToVietnameseWords(billions) + ' tỷ ';
    number %= 1e9;
  }

  if (number >= 1e6) {
    var millions = Math.floor(number / 1e6);
    result += numberToVietnameseWords(millions) + ' triệu ';
    number %= 1e6;
  }

  if (number >= 1e3) {
    var thousands = Math.floor(number / 1e3);
    if (thousands > 1) {
      result += units[thousands] + ' nghìn ';
    } else {
      result += 'nghìn ';
    }
    number %= 1e3;
  }

  if (number >= 100) {
    var hundreds = Math.floor(number / 100);
    result += units[hundreds] + ' trăm ';
    number %= 100;
  }

  if (number >= 10) {
    var tens = Math.floor(number / 10);
    if (tens == 1) {
      result += 'mười ';
    } else {
      result += units[tens] + ' mươi ';
    }
    number %= 10;
  }

  if (number > 0) {
    if (number == 1 && result != '') {
      result += 'mốt';
    } else if (number == 5 && tens > 1) {
      result += 'lăm';
    } else {
      result += units[number];
    }
  }

  return result.trim();
}
export const createConfirmationModal = async (title, content, onOkCallback) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title,
      content,
      onOk: async () => {
        try {
          await onOkCallback();
          resolve();
        } catch (error) {
          reject(error);
        }
      },
      okButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
      cancelButtonProps: {
        className:
          'font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs',
      },
    });
  });
};
