import { Button, Divider, Form, Select } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ProductDetail from '../pages/Product/productDetail';
import { getProducts } from '../redux/actions/product';

const { Option } = Select;

const SelectProduct = ({
    getProducts,
    products,
    handleSelectProduct,
    isView,
    value,
    currentData,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [quickAddId, setQuickAddId] = useState();

    useEffect(() => {
        getProducts(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectChange = async (value) => {
        const product = _.find(products.data, (item) => item.id === value);
        handleSelectProduct(product);
    };

    useEffect(() => {
        if (quickAddId && !products.isFetching) {
            const product = _.find(
                products.data,
                (item) => item.id === quickAddId
            );
            if (product) handleSelectChange(quickAddId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products.data, products.isFetching, quickAddId]);
    useEffect(() => {
        handleSelectProduct(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (
        isView &&
        !products.isFetching &&
        !_.isEmpty(currentData) &&
        currentData.product
    ) {
        products.data.push(currentData.product);
    }

    return (
        <>
            <Form.Item
                name="productId"
                label="Sản phẩm"
                rules={[
                    {
                        required: true,
                        message: 'Vui lòng chọn!',
                    },
                ]}
            >
                <Select
                    allowClear
                    placeholder="Chọn sản phẩm"
                    optionFilterProp="label"
                    showSearch
                    dropdownMatchSelectWidth={false}
                    // disabled={isView}
                    loading={products.isFetching}
                    onChange={handleSelectChange}
                    options={_.map(_.uniqBy(products.data, 'id'), (item) => ({
                        value: item.id,
                        label: item.frameNumber
                            ? item.name + '-' + item.frameNumber
                            : item.name,
                    }))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider
                                style={{
                                    margin: '8px 0',
                                }}
                            />
                            <Button
                                className="w-full"
                                type="text"
                                // icon={<PlusOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenModal(true);
                                }}
                            >
                                <i className="fas fa-plus mr-1"></i> Tạo mới sản
                                phẩm
                            </Button>
                        </>
                    )}
                >
                    <Option value="create">
                        <div>
                            <i className="fas fa-plus mr-1"></i> Tạo mới
                        </div>
                    </Option>
                </Select>
            </Form.Item>
            {openModal && (
                <ProductDetail
                    fetchProducts={getProducts}
                    quickAdd={setQuickAddId}
                    showModal={openModal}
                    setShowModal={setOpenModal}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        products: state.Product,
    };
};
export default connect(mapStateToProps, {
    getProducts,
})(SelectProduct);
