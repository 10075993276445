import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
} from '../../settings/action';

const token = JSON.parse(localStorage.getItem('token'));
const user = token && JSON.parse(localStorage.getItem('token')).employeeInfo;
const roles = token && JSON.parse(localStorage.getItem('token')).roles;
const initialState = token
    ? { isLoggedIn: true, user, roles }
    : { isLoggedIn: false, user: null, roles: null };

export default function auth(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                roles: payload.roles,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}
