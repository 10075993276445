import React from 'react';
import { Provider } from 'react-redux';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/store';
import { ConfigProvider } from 'antd';
import antdVi from 'antd/es/locale/vi_VN';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const store = configureStore();
root.render(
    <BrowserRouter basename={baseUrl}>
        <ConfigProvider locale={antdVi}>
            <Provider store={store}>
                <App />
            </Provider>
        </ConfigProvider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
