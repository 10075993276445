import client from '../../helpers/client';
import apiUrl from '../../settings';
import * as types from '../../settings/action';

export function getProducts(isSaled) {
    return async (dispatch) => {
        dispatch({ type: types.PRODUCT_FETCH_START });
        await client()
            .get(`${apiUrl}/Product/GetAllProductsByStatus`, {
                params: {
                    isSaled,
                },
            })
            .then((data) => {
                dispatch({
                    type: types.PRODUCT_FETCH_SUCCESS,
                    data: data.data || [],
                });
                return data;
            })
            .catch((err) => {
                dispatch({
                    type: types.PRODUCT_FETCH_FAILURE,
                    errors: err,
                });
                throw err;
            });
    };
}

export function createProduct(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().post(`${apiUrl}/Product`, data);
        return result;
    };
}

export function updateProduct(data) {
    // const { access_token } = getUser();
    return async (dispatch, state) => {
        const { data: result } = await client().put(
            `${apiUrl}/Product/id?id=${data.id}`,
            data
        );
        return result;
    };
}
