import { useEffect, useState } from 'react';

import { Checkbox, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import _ from 'lodash';
import ButtonAdd from '../../../components/elements/ButtonAdd';
import FormAddDetail from './formAddDetail';

const DetailList = ({ detail, setDetail, isView, masterId }) => {
    const [list, setList] = useState(detail.goodsReceiptNoteDetail);
    const [openAddDetail, setOpenAddDetail] = useState();
    const [currentData, setCurrentData] = useState({});

    const columns = [
        {
            title: () => {
                return <div className="editable-row">Tên Xe</div>;
            },
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: () => {
                return <div className="editable-row">Màu Xe</div>;
            },
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: () => {
                return <div className="editable-row">Số Khung</div>;
            },
            dataIndex: 'frameNumber',
            key: 'frameNumber',
        },
        {
            title: () => {
                return <div className="editable-row">Số Máy</div>;
            },
            dataIndex: 'engineNumber',
            key: 'engineNumber',
        },
        {
            title: () => {
                return <div className="editable-row">Số hóa đơn</div>;
            },
            dataIndex: 'numberContracts',
            key: 'numberContracts',
        },
        {
            title: () => {
                return <div className="editable-row">Ngày Đăng Kiểm</div>;
            },
            dataIndex: 'registrationDate',
            key: 'registrationDate',
            render: (text) => {
                return text ? dayjs(text).format('DD/MM/YYYY') : '';
            },
        },
        {
            title: () => {
                return <div className="editable-row">Giá</div>;
            },
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: () => {
                return <div className="editable-row">Số bảo hành</div>;
            },
            dataIndex: 'warrantyNumber',
            key: 'warrantyNumber',
            render: (text) => {
                return <Checkbox checked={text} disabled></Checkbox>;
            },
        },
        {
            title: () => {
                return <div className="editable-row">Đăng kiểm</div>;
            },
            dataIndex: 'registry',
            key: 'registry',
            render: (text) => {
                return <Checkbox checked={text} disabled></Checkbox>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            onCell: (record) => {
                return {
                    onClick: (event) => {
                        handleDelete(event, record);
                    },
                };
            },
            render: () => (
                <span>
                    <DeleteOutlined className="text-rose-600 text-lg" />
                </span>
            ),
        },
    ];

    useEffect(() => {
        setDetail({ ...detail, goodsReceiptNoteDetail: list });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length]);

    useEffect(() => {
        setList(detail.goodsReceiptNoteDetail);
    }, [detail.goodsReceiptNoteDetail]);

    const handleRowClick = (record, index) => {
        setCurrentData({ ...record, id: isView ? record.id : index });
        setOpenAddDetail(true);
    };

    const handleAddDetail = () => {
        setOpenAddDetail(true);
        setCurrentData({});
    };

    const handleDelete = (e, record, index) => {
        e.stopPropagation();
        setList(_.filter(list, (item) => item.detailId !== record.detailId));
    };

    return (
        <div>
            <div className="flex justify-end">
                <ButtonAdd onClick={handleAddDetail} text="Thêm" />
            </div>
            <Table
                rowKey="id"
                size="small"
                scroll={{ x: 'max-content' }}
                dataSource={[...list]}
                columns={columns}
                rowClassName={() => 'editable-row cursor-pointer'}
                onRow={(record, index) => ({
                    onClick: (event) => handleRowClick(record, index, event),
                })}
            />
            {openAddDetail && (
                <FormAddDetail
                    masterId={masterId}
                    isViewMaster={isView}
                    currentData={currentData}
                    openAddDetail={openAddDetail}
                    setOpenAddDetail={setOpenAddDetail}
                    setList={setList}
                    list={list}
                    setCurrentData={setCurrentData}
                />
            )}
        </div>
    );
};
export default DetailList;
