import { notification } from 'antd';

const createNotification = (type, message, description) => {
  notification.config({
    duration: 3,
  })
  notification[type]({
    message,
    description,
  });
};

export default createNotification;
